import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row, Modal } from 'react-bootstrap'
import CustomControl from '../../components/CustomControl'
import PasswordProtectedControl from '../../components/PasswordProtectedControl'
import AddressControl from '../../components/AddressControl'
import AvailabilityControl from '../../components/AvailabilityControl'
import {getEmployeeProfile, getPermissionsTemplate, getSSN, updateEmployeeProfile} from '../../services/HumanResourcesService';
import PageSpinner from '../../components/PageSpinner'
import RadioControl from '../../components/RadioControl'
import EmergencyContact from '../../components/EmergencyContact'
import { AddressValidator, ValidationGroup, Validator, validateEmail, validateExistence, validatePhoneNumber, validateSsnOptional } from '../../validation'
import { AppGlobals } from '../../App'
import CustomAlert from '../../components/CustomAlert'
import DropdownControl from '../../components/DropdownControl'
import CustomButton from '../../components/CustomButton'
import HRUserPayEditor from './HRPay/HRUserPayEditor'
import HumanResourcesTerminate from './HumanResourcesTerminate'

export default function HumanResourcesProfile({selectedEmployee, locations, employees, permissions}) {

    const [profileEmployee, setProfileEmployee] = useState(null);
    const [isloading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [contactsIsValid, setContactsIsValid] = useState(true);

    const [showRehireModal, setShowRehireModal] = useState(false);
    const [showTerminateModal, setShowTerminateModal] = useState(false);
    const [permissionsTemplate, setPermissionsTemplate] = useState([]);
    
    const validationGroup = new ValidationGroup();
    const addressValidator = new AddressValidator(profileEmployee?.address);
    let ssnValidator = new Validator(() => validateSsnOptional(profileEmployee?.ssn)).isValid();

    if(profileEmployee?.ssn === ''){
      ssnValidator = true;
    }

    async function handleLoadPermissionsTemplate() {
      const response = await getPermissionsTemplate();
      if (response.status === 200) {
        setPermissionsTemplate(response.permissionsTemplate);
      }
    }

    async function loadData(){
      setIsLoading(true);
      const response = await getEmployeeProfile(selectedEmployee.uid);
      if(response.status == '200'){
          setProfileEmployee(response.employee)
      }
      await handleLoadPermissionsTemplate();
      setIsLoading(false);
    }
    
    async function handleGetSSN(password) {
      const response = await getSSN(password, profileEmployee.uid);
      if (response.ssn !== '') {
          profileEmployee.ssn = response.ssn;
          setProfileEmployee({...profileEmployee})
          return true;
      }
      return false;
    }

    function handleSetUser(key, value) {
      const newUser = structuredClone(profileEmployee);
      newUser[key] = value;
      setProfileEmployee(newUser);
    }

    function handleSetContact(key, value, index){
      const newUser = structuredClone(profileEmployee);
      const newContact = newUser['emergencyContacts'][index];
      newContact[key] = value;
      setProfileEmployee(newUser);
    }

    function handleAddEmergencyContacts(){
      const newUser = structuredClone(profileEmployee);
      newUser['emergencyContacts'] = [...profileEmployee.emergencyContacts, {firstName: '', lastName: '', phoneNumber: '', email: '', relationship: '', address: {}}];
      setProfileEmployee(newUser);
    }

    function handleDeleteContact(deleteIndex){
      const newUser = structuredClone(profileEmployee);
      const newContacts = newUser['emergencyContacts'].toSpliced(deleteIndex, 1);
      newUser['emergencyContacts'] = newContacts;
      setProfileEmployee(newUser);
    }

    useEffect(()=>{
        loadData();
    }, [selectedEmployee])

    async function handleSubmit(){    
      setIsSubmitting(true);
      const response = await updateEmployeeProfile({
        ...profileEmployee, 
        dateOfBirth: !!profileEmployee.dateOfBirth ? profileEmployee.dateOfBirth : null,
        middleName: !!profileEmployee.middleName ? profileEmployee.middleName : null,
      });
      if(response.status === 200){
          AppGlobals.alert(<CustomAlert type='success' title={response.message}/>);
      }else{
          AppGlobals.alert(<CustomAlert type='danger' title={response.message}/>);
      }
      setIsSubmitting(false);
  }

    return (
      <>
      {(!profileEmployee || isloading) ?
        <PageSpinner/>
          :
        <Container fluid style={{paddingTop: 8}}>
          <Row>
            <Col style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 12}}>
              {selectedEmployee.pay.payType !== -1 ?
              permissions.hrTerminate === 1 && <Button onClick={()=> setShowTerminateModal(true)}  variant={'outline-danger'}>Terminate Employee</Button> :
              permissions.hrHire === 1 && <Button onClick={()=> setShowRehireModal(true)}  variant={'outline-primary'}>Rehire Employee</Button>}
            </Col>
          </Row>
            <Row style={{padding: 8, border: '1px solid lightgray', borderRadius: 6, marginBottom: 8}}>
                <Col lg={12}>
                    <p style={{fontWeight: 'bold', fontSize: 24, width: '100%', textAlign: 'left'}}>Employment Information</p>
                </Col>
               <Col lg={12}>
                   <CustomControl
                        maxLength={50} 
                        type='text' 
                        title='Employee ID'
                        floatingLabel
                        style={{marginBottom: 14}}
                        value={profileEmployee.employeeId}
                        setValue={(value) => {handleSetUser('employeeId', value)}}
                        validator={validationGroup.createValidator(() => validateExistence(profileEmployee.employeeId))}
                      />
                </Col>
                <RadioControl 
                    lg={6}
                    title='Location' 
                    inline
                    selection={profileEmployee.locationIdentifier} 
                    setSelection={(value) => handleSetUser('locationIdentifier', value)} 
                    optionNames={locations.map(l=>l.name)} 
                    optionValues={locations.map(l=>l.uid)}
                />
                <AvailabilityControl 
                    lg={6}
                    title='Availability' 
                    inline 
                    value={profileEmployee.availability}
                    setValue={(value)=>{handleSetUser('availability', value)}}
                  />
                <DropdownControl 
                    title='Direct Supervisor' 
                    value={profileEmployee?.supervisor} 
                    setValue={(value) => {handleSetUser('supervisor', value)}} 
                    itemNames={employees.filter(e=>e.uid !== profileEmployee.uid).map(e => e.firstName + ' ' + e.lastName)} 
                    itemValues={employees.filter(e=>e.uid !== profileEmployee.uid).map(e=> e.uid)}
                  />
            </Row>
            <Row style={{padding: 8, border: '1px solid lightgray', borderRadius: 6, marginBottom: 8}}>
                <Col lg={12}>
                    <p style={{fontWeight: 'bold', fontSize: 24, width: '100%', textAlign: 'left'}}>Personal Information</p>
                </Col>
                <Col lg={4}>
                  <CustomControl
                      maxLength={30} 
                      type='text' 
                      title='First Name'
                      floatingLabel={true} 
                      style={{marginBottom: 10}}
                      value={profileEmployee.firstName}
                      setValue={(value) => {handleSetUser('firstName', value)}}
                      validator={validationGroup.createValidator(() => validateExistence(profileEmployee.firstName))}
                      />
                </Col>
                <Col lg={4}>
                  <CustomControl
                      maxLength={30} 
                      type='text' 
                      title='Middle Name'
                      floatingLabel={true} 
                      style={{marginBottom: 10}}
                      value={profileEmployee.middleName ?? ''}
                      setValue={(value) => {handleSetUser('middleName', value)}}
                      />
                </Col>
                <Col lg={4}>
                  <CustomControl
                      maxLength={30} 
                      type='text' 
                      title='Last Name'
                      floatingLabel={true} 
                      style={{marginBottom: 10}}
                      value={profileEmployee.lastName}
                      setValue={(value) => {handleSetUser('lastName', value)}}
                      validator={validationGroup.createValidator(() => validateExistence(profileEmployee.lastName))}
                      />
                </Col>
                <Col lg={4}>
                  <CustomControl
                      maxLength={50} 
                      type='text' 
                      title='Email Address'
                      floatingLabel={true} 
                      style={{marginBottom: 10}}
                      value={profileEmployee.email}
                      setValue={(value) => {handleSetUser('email', value)}}
                      validator={validationGroup.createValidator(() => validateEmail(profileEmployee.email))}
                      />
                </Col>
                <Col lg={4}>
                  <CustomControl
                      maxLength={10} 
                      type='text' 
                      title='Phone Number'
                      floatingLabel={true} 
                      style={{marginBottom: 10}}
                      value={profileEmployee.phoneNumber}
                      setValue={(value) => {handleSetUser('phoneNumber', value)}}
                      validator={validationGroup.createValidator(() => validatePhoneNumber(profileEmployee.phoneNumber))}
                      />
                </Col>
                <Col lg={4}>
                  <CustomControl
                      type='date' 
                      title='Date of Birth'
                      floatingLabel={true} 
                      style={{marginBottom: 10}}
                      value={profileEmployee.dateOfBirth ?? ''}
                      setValue={(value) => {handleSetUser('dateOfBirth', value)}}
                      />
                </Col>
                  <PasswordProtectedControl lg={4} maxLength={9} errorMsg='Incorrect Password'  type='number' label='Social Security Number' style={{padding: 16}} value={profileEmployee.ssn ?? ''} setValue={(value) => handleSetUser('ssn', value)} callBack={handleGetSSN} />
                  <AddressControl validator={addressValidator} title='Address' lg={4} address={profileEmployee.address} originalValue={profileEmployee.address} setAddress={(value) => {handleSetUser('address', value)}}/>
              </Row>
              <Row style={{padding: 8, border: '1px solid lightgray', borderRadius: 6, marginBottom: 8}}>
                <Col lg={12}>
                    <p style={{fontWeight: 'bold', fontSize: 24, width: '100%', textAlign: 'left'}}>Emergency Contacts</p>
                    {profileEmployee.emergencyContacts && 
                      profileEmployee.emergencyContacts.map((contact, i)=>{
                        return <EmergencyContact setContactsIsValid={setContactsIsValid} key={i} contact={contact} index={i} handleDeleteContact={handleDeleteContact} handleSetContact={handleSetContact}/>
                      })
                    }
                    <Button onClick={handleAddEmergencyContacts} style={{marginTop: 10}} variant={'outline-primary'}>Add Emergency Contact</Button>
                </Col>
              </Row>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <CustomButton isLoading={isSubmitting} label={'Save Changes'} disabled={isSubmitting || !validationGroup.isValid() || !addressValidator.isValid() || !ssnValidator || !contactsIsValid} onClick={handleSubmit}/>
              </div>
              <Modal size='xl' onHide={()=>setShowRehireModal(false)} show={showRehireModal}>
                <HRUserPayEditor userIdentifier={selectedEmployee.uid} nonEffectivePay={[]} permissionsTemplate={permissionsTemplate} handlePayCrud={() => {window.location.reload()}}/>
              </Modal>
              <Modal size='xl' show={showTerminateModal} onHide={()=>setShowTerminateModal(false)} backdrop='static'>
                <HumanResourcesTerminate loadHub={() => {window.location.reload()}} selectedEmployee={selectedEmployee}/>
              </Modal>
          </Container>
            }
          </>
  )
}