/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function EDDocumentPreview({ document }) {
  const [numPages, setNumPages] = useState(undefined);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  return (
    <>
      <Modal.Header closeButton/>
      <Modal.Body
        style={{
          width: "100%",
          overflow: "scroll",
          padding: 12,
          display: "flex",
          flexDirection: "column",
          backgroundColor: "lightgray",
        }}
      >
        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
          <Document
            file={document.base64}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 16,
                gap: 16,
                position: "relative",
              }}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  size="LETTER"
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                />
              ))}
            </div>
          </Document>
        </div>
      </Modal.Body>
    </>
  );
}
