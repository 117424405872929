import { faClipboardQuestion, faCouch, faFile, faFileExcel, faFileImage, faFileLines, faFilePdf, faFileVideo, faFileWord, faGraduationCap, faPersonCircleQuestion, faPersonCircleXmark, faPersonRunning, faPhone, faPlaneDeparture, faTruck, faUserTie } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Col from 'react-bootstrap/Col';
import Cookies from "universal-cookie";

export const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export function checkPermission(permission){
    const permissions = new Cookies().get('userData')?.pay?.permissions ?  new Cookies().get('userData').pay.permissions : {}
    return Boolean(permissions[permission])
}

export function formatPhoneNumber(str) {
    return '(' + str.slice(0, 3) + ') ' + str.slice(3, 6) + '-' + str.slice(6);
}

export function formatSSN(str) {
    return str.slice(0, 3) + '-' + str.slice(3, 5) + '-' + str.slice(5);
}

export function formatEin(ein) {
    return ein.toString().slice(0, 2) + '-' + ein.toString().slice(2);
} 

export function formatTime(time, format) {
    return moment(moment().format('YYYY-MM-DD') + ' ' + time).format(format);
}

export function parseAvailability(str) {
    const array = [];
    ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].forEach((dayOfWeek) => {
        array.push(str.includes(dayOfWeek));
    });
    return array;
}

export function encodeAvailability(availability) {
    const daysOfTheWeek = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    let arr = [];
    for (let i = 0; i < 7; i++) {
        if (availability[i]) {
            arr.push(daysOfTheWeek[i]);
        }
    }
    return arr.join(', ');
}

export function availabilityToString(availability) {
    const daysOfTheWeek = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    let str = ''

    for (let i = 0; i < 7; i++) {
        if (availability[i]) {
            if (str !== '') {
                str += ', ';
            }
            str += daysOfTheWeek[i];
        }
    }
    return str;
}

export function availabilityToStringShort(availability) {
    const daysOfTheWeek = ['Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
    let str = ''

    for (let i = 0; i < 7; i++) {
        if (availability[i]) {
            if (str !== '') {
                str += ', ';
            }
            str += daysOfTheWeek[i];
        }
    }
    return str;
}

export function availabilityToBinaryString(availability) {
    let str = '';
    ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].forEach((dayOfWeek) => {
        str += (availability.includes(dayOfWeek) ? '1' : '0');
    });
    return str;
}

export function packageAddress(object) {
    object.address = {
        addressIdentifier: object.addressIdentifier,
        thoroughfare: object.thoroughfare, 
        premise: object.premise, 
        locality: object.locality, 
        administrativeArea: object.administrativeArea, 
        postalCode: object.postalCode
    }
}

export function addressComponentsToString(thoroughfare, premise, locality, administrativeArea, postalCode) {
    return addressToString({thoroughfare, premise, locality, administrativeArea, postalCode});
}

export function addressToString(address) {
    if (address) {
        let str = '';

        if (address.thoroughfare) {
            str += address.thoroughfare;
        }
        if (address.premise) {
            if (str.length > 0) {
                str += ', ';
            }
            str += address.premise;
        }
        if (address.locality) {
            if (str.length > 0) {
                str += ', ';
            }
            str += address.locality;
        }
        if (address.administrativeArea) {
            if (str.length > 0) {
                str += ', ';
            }
            str += address.administrativeArea;
        }
        if (address.postalCode) {
            if (str.length > 0) {
                str += ' ';
            }
            str += address.postalCode
        }
        return str;
    } else {
        return '';
    }
}

export const payTypeDictionary = {
    0: 'Per Hour',
    1: 'Per Year (non-exempt)',
    2: 'Per Year (exempt)',
}

export const terminationTypeDictionary = {
    1: 'Resignation',
    2: 'Abandonment',
    3: 'Retirement',
    4: 'Terminate for Cause',
    5: 'Layoff',
    6: 'End of Contract',
}

export function timeOffStatusDictionary(status){
    switch(status){
        case -1:
            return 'Rejected';
        case 0:
            return 'Pending';
        case 1:
            return 'Accepted';
        default:
            break;
    }
}


export const stateDictionary = {
    'Alabama': 'AL',
    'Alaska': 'AK',
    'Arizona': 'AZ',
    'Arkansas': 'AR',
    'California': 'CA',
    'Colorado': 'CO',
    'Connecticut': 'CT',
    'Delaware': 'DE',
    'Florida': 'FL',
    'Georgia': 'GA',
    'Hawaii': 'HI',
    'Idaho': 'ID',
    'Illinois': 'IL',
    'Indiana': 'IN',
    'Iowa': 'IA',
    'Kansas': 'KS',
    'Kentucky': 'KY',
    'Louisiana': 'LA',
    'Maine': 'ME',
    'Maryland': 'MD',
    'Massachusetts': 'MA',
    'Michigan': 'MI',
    'Minnesota': 'MN',
    'Mississippi': 'MS',
    'Missouri': 'MO',
    'Montana': 'MT',
    'Nebraska': 'NE',
    'Nevada': 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    'Ohio': 'OH',
    'Oklahoma': 'OK',
    'Oregon': 'OR',
    'Pennsylvania': 'PA',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    'Tennessee': 'TN',
    'Texas': 'TX',
    'Utah': 'UT',
    'Vermont': 'VT',
    'Virginia': 'VA',
    'Washington': 'WA',
    'West Virginia': 'WV',
    'Wisconsin': 'WI',
    'Wyoming': 'WY'
};

export const employeeTypeDictionary = {
    0: 'Full-Time',
    1: 'Part-Time'
}

export const ptoAccrualTypeDictionary = {
    0: 'Per 40 Hours',
    1: 'Per Pay Period'
}

export const OnboardingDocuments = {
    'w4': 'W-4 (Employee’s Withholding Certificate)',
    'i9': 'I-9 (Employment Eligibility Verification)',
    'f8850': 'Form 8850 (for Work Opportunity Credit)',
}

export const DirectDepositDocuments = {
    'dd': 'Direct Deposit Authorization',
    'mdd': 'Multiple Direct Deposit Authorization',
}

export const taxDocumentDictionary = {
    'a4': 'A4 (Alabama)',
    'wh4': 'WH-4 (Indiana)',
    'k4': 'K-4 (Kentucky)',
    'l4': 'L-4 (Louisiana)',
    'miw4': 'MI-W4 (Michigan)',
    'it2104': 'IT-2104 (New York)',
    'it4': 'IT-4 (Ohio)',
    'rev419': 'REV-419 (Pennsylvania)',
    'va4': 'VA-4 (Virginia)'
}

export const OnboardingDocumentsShort = {
    w4: 'W-4',
    i9: 'I-9',
    f8850: 'Form 8850',
}

export const TaxDocumentsShort = {
    a4: 'A4',
    wh4: 'WH-4',
    k4: 'K-4',
    l4: 'L-4',
    miw4: 'MI-W4',
    it2104: 'IT-2104',
    it4: 'IT-4',
    rev419: 'REV-419',
    va4: 'VA-4',
}

export const OnboardingDocumentsReadOnly = {
    'archivedDocument': 'Archived Document',
    'offerLetter': 'Offer Letter', 
    ...OnboardingDocuments,
    ...taxDocumentDictionary,
    ...DirectDepositDocuments
}

export const stateTaxDocuments = {
    'AL': 'a4',
    'IN': 'wh4',
    'KY': 'k4',
    'LA': 'l4',
    'MI': 'miw4',
    'NY': 'it2104',
    'OH': 'it4',
    'PA': 'rev419',
    'VA': 'va4'
}

export async function downloadBase64(base64, title) {
    const response = await fetch(base64);
    const blob = await response.blob();
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = title;        
    document.body.appendChild(elem);
    elem.click();        
    document.body.removeChild(elem);
}

export function durationToString(durationInMinutes) {

    let totalMinutes = durationInMinutes;
    
    let days = 0;
    let hours = 0;
    let minutes = 0;

    while (totalMinutes >= 1440) {
        days += 1;
        totalMinutes -= 1440;
    }
    while (totalMinutes >= 60) {
        hours += 1;
        totalMinutes -= 60;
    }
    minutes = totalMinutes;
    
    const arr = [];
    if (days) {
        arr.push(days > 1 ? days + ' days' : '1 day');
    }
    if (hours) {
        arr.push(hours > 1 ? hours + ' hours' : '1 hour');
    }
    if (minutes) {
        arr.push(minutes > 1 ? minutes + ' minutes' : '1 minute');
    }

    const str = arr.join(', ');

    return str;
}

export function validateDecimal(value) {
    return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
}

export function validateDecimalFixed(value, decimalPlaces) {
    return parseFloat(validateDecimal(value).toFixed(decimalPlaces));
}

export function validateInteger(value) {
    return isNaN(parseInt(value)) ? 0 : parseInt(value);
}

export function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function wrapElementInCol(element, breakpoints) {
    return (
        <Col style={{marginBottom: 8}} xs={breakpoints.xs} sm={breakpoints.sm} md={breakpoints.md} lg={breakpoints.lg} xl={breakpoints.xl} xxl={breakpoints.xxl}>
            {element}
        </Col>
    )
}

export function dateRangeToString(startDate, endDate) {
    if (!startDate && !endDate) {
        return 'All Time';
    } else if (!startDate) {
        return 'Before ' + moment(endDate).format('MMM D, YYYY');
    } else if (!endDate) {
        return 'After ' + moment(startDate).format('MMM D, YYYY');
    } else {
        return moment(startDate).format('MMM D, YYYY') + ' - ' + moment(endDate).format('MMM D, YYYY');
    }
}

export function dateIsInRange(date, startDate, endDate) {
    if (!startDate && !endDate) {
        return true;
    } else if (!startDate) {
        return moment(endDate).diff(moment(date), 'days') >= 0;
    } else if (!endDate) {
        return moment(date).diff(moment(startDate), 'days') >= 0;
    } else {
        return moment(date).isBetween(moment(startDate), moment(endDate), 'days', '[]');
    }
}

export function getFileIcon(documentType) {
    switch (documentType) {
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'heic':
        case 'heic':
            return faFileImage;
        case 'pdf':
            return faFilePdf;
        case 'doc':
        case 'docx':
        case 'odt':
        case 'rtf':
        case 'tex':
        case 'wpd':
            return faFileWord;
        case 'txt':
            return faFileLines;
        case 'xlsx':
        case 'xls':
        case 'xlsm':
        case 'csv':
        case 'tsv':
        case 'xml':
            return faFileExcel;
        case 'mp4':
        case 'avi':
        case 'wmv':
        case 'mov':
        case 'flv':
        case 'avchd':
        case 'mpeg':
            return faFileVideo;
        default:
            return faFile;
    }
}

export async function saveFile(name, base64) {
    const fetchResponse = await fetch(base64);
    const blob = await fetchResponse.blob();
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = name;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
}

export function base64ToFile(base64, filename) {
    // Split the base64 string to get the content
    const [metadata, base64Data] = base64.split(',');
    
    // Decode the base64 string to a binary string
    const byteCharacters = atob(base64Data);
    
    // Create an array of byte numbers
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    
    // Create a Uint8Array from the byte numbers
    const byteArray = new Uint8Array(byteNumbers);
    
    // Determine the MIME type from metadata
    const mimeType = metadata.match(/data:(.*);base64/)?.[1] || 'application/octet-stream';
    
    // Create a Blob object
    const blob = new Blob([byteArray], { type: mimeType });
    
    // Create a File object
    return new File([blob], filename, { type: mimeType });
  }

export async function getBase64FromFile(file, callback) {
    if (file) {
        const reader = new FileReader();
        reader.onerror = () => {
            console.log(reader.error);
        }
        reader.onload = () => {
            callback(reader.result);
        }
        reader.readAsDataURL(file);
    }
    callback(undefined);
}


export function intToTime(num) {
    const hours = parseInt(num / 60);
    const pm = hours >= 12;

    const hoursString = pm ? (hours - 12).toString() : hours === 0 ? '12' : hours.toString();
    const minutesString = (num % 60).toString().padStart(2, '0');

    return `${hoursString}:${minutesString} ${pm ? 'PM' : 'AM'}`;
}

export const pandaDocStatusDictionary = {
    'document.uploaded': 'Uploaded',
    'document.draft': 'Draft',
    'document.sent': 'Sent',
    'document.viewed': 'Viewed',
    'document.waiting_approval': 'Waiting Approval',
    'document.rejected': 'Rejected',
    'document.approved': 'Approved',
    'document.waiting_pay': 'Waiting Pay',
    'document.paid': 'Paid',
    'document.completed': 'Completed',
    'document.voided': 'Voided',
    'document.declined': 'Declined',
}

export function filterArrayByObjectProperty(array, key, value) {
    return array.filter(a => a[key]?.toString().toLowerCase().includes(value?.toLowerCase()));
} 


let fakeUID = 0;

export function getFakeUID() {
    fakeUID--;
    return fakeUID;
}

export function formatTimeNew(str) {
    if (!str) {
        return '';
    }
    const hours = parseInt(str.substring(0, 2));
    const minutes = parseInt(str.substring(3));
    const pm = hours > 12;
    const addLeadingZero = minutes < 10;

    return `${pm ? hours - 12 : hours}:${addLeadingZero ? '0' : ''}${minutes} ${pm ? 'PM' : 'AM'}`;
}

export function closestDateBeforeOrOnDayIndex(dateStr, dayIndex) {
    // Parse the input date string to a Date object
    const date = moment(dateStr, 'YYYY-MM-DD');
    
    // Get the current day index (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const currentDayIndex = date.day(); // getDay() returns 0-6

    // Calculate how many days to subtract to reach the desired day index
    const daysToSubtract = (currentDayIndex - dayIndex + 7) % 7;

    // Subtract the days from the current date
    date.subtract(daysToSubtract);

    return date.format('YYYY-MM-DD')
}

export function parseOfferLetter(content, data){
    content = content.replaceAll('[CompanyName]', data.companyName);
    content = content.replaceAll('[CreatedDate]', moment().format('MMM D, YYYY'));
    content = content.replaceAll('[EmployeeName]', data.employeeName);
    content = content.replaceAll('[EmployeeType]', employeeTypeDictionary[data.employeeType]);
    content = content.replaceAll('[StartDate]', moment(data.startDate).format('MMM D, YYYY'));
    content = content.replaceAll('[SupervisorName]', data.supervisorName);
    content = content.replaceAll('[LocationAddress]', addressToString(data.locationAddress));
    content = content.replaceAll('[Pay]', `${usdFormatter.format(data.payRate)} ${payTypeDictionary[data.payType]}`);
    content = content.replaceAll('[ExpectedAvailability]', availabilityToString(data.expectedAvailability));
    content = content.replaceAll('[Benefits]', data.benefits ?? 'There are currently no available benefits for this employer');
    content = content.replaceAll('[OfferLetterManagerName]', data.offerLetterManagerName);
    content = content.replaceAll('[OfferLetterManagerPhoneNumber]', formatPhoneNumber(data.offerLetterManagerPhoneNumber));
    content = content.replaceAll('[OfferLetterManagerEmail]', data.offerLetterManagerEmail);
    return content;
}