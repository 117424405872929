/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import { getResponse, getToken } from "./serviceTools";

export async function getHumanResourceEmployees(date) {
    const data = {
        type: 'hr',
        target: 'hub',
        token: getToken(),
        date
    }

    const response = await getResponse(data);
    return response;
}

//////////////////
/// HR PROFILE
//////////////////
export async function getEmployeeProfile(userIdentifier) {
    const data = {
        type: 'hr',
        target: 'getProfile',
        token: getToken(),
        userIdentifier
    }

    const response = await getResponse(data);
    return response;
}

export async function getSSN(password, userIdentifier) {
    const data = {
        type: 'hr',
        target: 'getSSN',
        token: getToken(),
        password,
        userIdentifier
    }

    const response = await getResponse(data);
    return response;
}

export async function updateEmployeeProfile(employee){
    var data = {
        type: 'hr',
        target: 'updateProfile',
        token: getToken(),
        employee
    }

    return await getResponse(data);
}

//////////////////
/// HIRE
//////////////////

export async function getHireInfo(){
    var data = {
        type: 'hr',
        target: 'getHireInfo',
        token: getToken(),
    }

    return await getResponse(data);
}

export async function hireNewEmployee(employee, sendOnboarding){
    var data = {
        type: 'hr',
        target: 'hireEmployee',
        token: getToken(),
        employee,
        sendOnboarding
    }

    return await getResponse(data);
}

//////////////////
/// TERMINATE

//////////////////
export async function terminateEmployee(termination){
    var data = {
        type: 'hr',
        target: 'terminateEmployee',
        token: getToken(),
        termination,
    }

    return await getResponse(data);
}

//////////////////
/// HR SCHEDULE
//////////////////

export async function getEmployeeSchedule(userIdentifier) {
    var data = {
        type: 'hr',
        target: 'getSchedule',
        token: getToken(),
        userIdentifier: userIdentifier
    }

    return await getResponse(data);
}

export async function createCallOff(callOff) {
    var data = {
        type: 'hr',
        target: 'createCallOff',
        token: getToken(),
        callOff: callOff
    }

    return await getResponse(data);
}

export async function getEmployeeFutureTimeOff(userIdentifier) {
    var data = {
        type: 'hr',
        target: 'getEmployeeFutureTimeOff',
        token: getToken(),
        userIdentifier: userIdentifier
    }

    return await getResponse(data);
}

export async function getAllFutureTimeOff() {
    var data = {
        type: 'hr',
        target: 'getAllFutureTimeOff',
        token: getToken(),
    }

    return await getResponse(data);
}

export async function acceptRequestOff(uid, ptoArray) {
    var data = {
        type: 'hr',
        target: 'acceptRequestOff',
        token: getToken(),
        uid: uid,
        ptoArray: ptoArray
    }

    return await getResponse(data);
}

export async function rejectRequestOff(uid) {
    var data = {
        type: 'hr',
        target: 'rejectRequestOff',
        token: getToken(),
        uid: uid
    }

    return await getResponse(data);
}

export async function createTimeOff(timeOff, ptoArray) {
    var data = {
        type: 'hr',
        target: 'createTimeOff',
        token: getToken(),
        timeOff: timeOff,
        ptoArray: ptoArray
    }

    return await getResponse(data);
}

//////////////////
/// HR TIMESHEETS
//////////////////
export async function getTimesheetEntries(userIdentifier, startDate, endDate) {
    var data = {
        type: 'hr',
        target: 'getAllTimesheets',
        token: getToken(),
        userIdentifier,
        startDate,
        endDate
    }

    const response = await getResponse(data);
    return response;
}

export async function createTimesheetEntry(timesheetEntry) {
    var data = {
        type: 'hr',
        target: 'createTimesheet',
        token: getToken(),
        timesheetEntry
    }

    return await getResponse(data);
}

export async function updateTimesheetEntry(timesheetEntry) {
    var data = {
        type: 'hr',
        target: 'updateTimesheet',
        token: getToken(),
        timesheetEntry
    }

    return await getResponse(data);
}

export async function deleteTimesheetEntry(timesheetEntryIdentifier) {
    var data = {
        type: 'hr',
        target: 'deleteTimesheet',
        token: getToken(),
        timesheetEntryIdentifier
    }

    return await getResponse(data);
}

//////////////////
/// HR PAY
//////////////////

export async function getAllEmployeePay(userIdentifier) {
    var data = {
        type: 'hr',
        target: 'getAllPay',
        token: getToken(),
        userIdentifier: userIdentifier
    }

    return await getResponse(data);
}

export async function createEmployeePay(pay, sendOfferLetter) {
    var data = {
        type: 'hr',
        target: 'createPay',
        token: getToken(),
        pay: pay,
    }

    return await getResponse(data);
}

export async function updateEmployeePay(pay) {
    var data = {
        type: 'hr',
        target: 'updatePay',
        token: getToken(),
        pay: pay,
    }

    return await getResponse(data);
}

//////////////////
/// HR PTO
//////////////////

export async function getEmployeePto(userIdentifier, startDate, endDate) {
    var data = {
        type: 'hr',
        target: 'getPto',
        token: getToken(),
        userIdentifier: userIdentifier,
        startDate: startDate,
        endDate: endDate
    }

    return await getResponse(data);
}

export async function createEmployeePto(pto) {
    var data = {
        type: 'hr',
        target: 'createPto',
        token: getToken(),
        pto: pto,
    }

    return await getResponse(data);
}

export async function updateEmployeePto(pto) {
    var data = {
        type: 'hr',
        target: 'updatePto',
        token: getToken(),
        pto: pto,
    }

    return await getResponse(data);
}

export async function deleteEmployeePto(uid) {
    var data = {
        type: 'hr',
        target: 'deletePto',
        token: getToken(),
        uid: uid,
    }

    return await getResponse(data);
}

export async function getPtoBalance(userIdentifier) {
    var data = {
        type: 'hr',
        target: 'getPtoBalance',
        token: getToken(),
        userIdentifier: userIdentifier,
    }

    return await getResponse(data);
}

//////////////////
/// HR LOANS
//////////////////

export async function getAllEmployeeLoans(userIdentifier) {
    var data = {
        type: 'hr',
        target: 'getAllLoans',
        token: getToken(),
        userIdentifier: userIdentifier,
    }

    return await getResponse(data);
}

export async function getEmployeeLoan(uid) {
    var data = {
        type: 'hr',
        target: 'getLoan',
        token: getToken(),
        uid: uid,
    }

    return await getResponse(data);
}

export async function createEmployeeLoan(loan) {
    var data = {
        type: 'hr',
        target: 'createLoan',
        token: getToken(),
        loan: loan,
    }

    return await getResponse(data);
}

export async function createEmployeeLoanInstallment(loanInstallment) {
    var data = {
        type: 'hr',
        target: 'createLoanInstallment',
        token: getToken(),
        loanInstallment: loanInstallment,
    }

    return await getResponse(data);
}

export async function updateEmployeeLoan(loan) {
    var data = {
        type: 'hr',
        target: 'updateLoan',
        token: getToken(),
        loan: loan,
    }

    return await getResponse(data);
}

export async function deleteEmployeeLoan(uid) {
    var data = {
        type: 'hr',
        target: 'deleteLoan',
        token: getToken(),
        uid: uid,
    }

    return await getResponse(data);
}

//////////////////
/// HR Documents
//////////////////

export async function getEmployeeDocuments(userIdentifier) {
    var data = {
        type: 'hr',
        target: 'getAllDocuments',
        token: getToken(),
        userIdentifier: userIdentifier,
    }

    return await getResponse(data);
}

// ED Documents
export async function downloadDocumentRequest(documentRequestIdentifier){
    var data = {
        type: 'hr',
        target: 'downloadDocumentRequest',
        token: getToken(),
        documentRequestIdentifier,
    }

    return await getResponse(data);
}

export async function getDocumentRequestData(userIdentifier){
    var data = {
        type: 'hr',
        target: 'getDocumentRequestData',
        token: getToken(),
        userIdentifier,
    }

    return await getResponse(data);
}

export async function createDocumentRequest(userIdentifier, documents, employerEmail, includeEContacts, startDate, customOnboardingDocumentIdentifiers){
    var data = {
        type: 'hr',
        target: 'createDocumentRequest',
        token: getToken(),
        userIdentifier,
        documents,
        employerEmail,
        includeEContacts,
        startDate,
        customOnboardingDocumentIdentifiers
    }

    return await getResponse(data);
}

export async function sendBackEdDocument(documentRequestIdentifier, documentType, message, uid){
    var data = {
        type: 'hr',
        target: 'sendBackEdDocument',
        token: getToken(),
        documentRequestIdentifier,
        documentType,
        message,
        uid
    }
    return await getResponse(data);
}

export async function sendBackDlOrSsn(documentRequestIdentifier, documentType, message){
    var data = {
        type: 'hr',
        target: 'sendBackDlOrSsn',
        token: getToken(),
        documentRequestIdentifier,
        documentType,
        message
    }
    return await getResponse(data);
}

export async function downloadDocument(uid, documentType){
    var data = {
        type: 'hr',
        target: 'downloadEdDocument',
        token: getToken(),
        uid,
        documentType,
    }

    return await getResponse(data);
}

export async function downloadSsnOrDl(uid, documentType){
    var data = {
        type: 'hr',
        target: 'downloadDlOrSsnDocument',
        token: getToken(),
        uid,
        documentType,
    }

    return await getResponse(data);
}

export async function sendDocumentRequestInvitation(uid){
    var data = {
        type: 'hr',
        target: 'sendDocumentRequestInvitation',
        token: getToken(),
        uid,
    }

    return await getResponse(data);
}

//Custom Documents

export async function getEmployeeDocument(uid) {
    var data = {
        type: 'hr',
        target: 'getDocument',
        token: getToken(),
        uid: uid,
    }

    return await getResponse(data);
}

export async function createEmployeeDocument(document) {
    var data = {
        type: 'hr',
        target: 'createDocument',
        token: getToken(),
        document: document,
    }

    return await getResponse(data);
}

export async function updateEmployeeDocument(document) {
    var data = {
        type: 'hr',
        target: 'updateDocument',
        token: getToken(),
        document: document,
    }

    return await getResponse(data);
}

export async function deleteEmployeeDocument(uid) {
    var data = {
        type: 'hr',
        target: 'deleteDocument',
        token: getToken(),
        uid: uid,
    }

    return await getResponse(data);
}

export async function sendEmployeeDocument(userIdentifier, documentType) {
    var data = {
        type: 'hr',
        target: 'sendDocument',
        token: getToken(),
        userIdentifier: userIdentifier,
        documentType: documentType,
    }

    return await getResponse(data);
}

//////////////////
/// HR PERMISSIONS
//////////////////
export async function getHumanResourcePermissions(userIdentifier){
    const data = {
        type: 'hr',
        target: 'getPermissions',
        token: getToken(),
        userIdentifier
    }

    const reponse = await getResponse(data);
    return reponse;
}

export async function updateHumanResourcePermissions(permissions) {
    const data = {
        type: 'hr',
        target: 'updatePermissions',
        token: getToken(),
        permissions
    }

    return await getResponse(data);
}

export async function getPermissionsTemplate() {
    const data = {
        type: 'hr',
        target: 'getPermissionsTemplate',
        token: getToken(),
    }

    return await getResponse(data);
}