import React, { useEffect } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import CustomControl from './CustomControl'
import AddressControl from './AddressControl'
import { AddressValidator, ValidationGroup, validateEmail, validateExistence, validatePhoneNumber } from '../validation';
import AddressAutoFill from './AddressAutoFill';


export default function EmergencyContact({contact, index, handleDeleteContact, handleSetContact, setContactsIsValid}) {
    const validationGroup = new ValidationGroup();
    
    useEffect(()=>{
        setContactsIsValid(validationGroup.isValid());
    }, [contact])

    return (
        <Form.Group>
            <Form.Label style={{display: 'flex', fontSize: 20}}>
                    Emergency Contact #{index+1}
                    <Button style={{marginLeft: 'auto'}} variant='outline-danger' onClick={()=>handleDeleteContact(index)}>Delete Contact</Button>
            </Form.Label>
            <Container fluid style={{borderRadius: 6, padding: 12, marginBottom: 8}}>
                <Row style={{marginTop: 8}}>
                    <Col lg={4}>
                        <CustomControl
                            maxLength={30} 
                            type='text' 
                            title='First Name'
                            floatingLabel={true} 
                            style={{marginBottom: 10}}
                            value={contact.firstName}
                            setValue={(value)=>handleSetContact('firstName', value, index)}
                            validator={validationGroup.createValidator(() => validateExistence(contact.firstName))}
                        />
                    </Col>
                    <Col lg={4}>
                        <CustomControl
                            maxLength={30} 
                            type='text' 
                            title='Last Name'
                            floatingLabel={true} 
                            style={{marginBottom: 10}}
                            value={contact.lastName}
                            setValue={(value)=>handleSetContact('lastName', value, index)}
                            validator={validationGroup.createValidator(() => validateExistence(contact.lastName))}
                        />
                    </Col>
                    <Col lg={4}>
                        <CustomControl
                            type='text' 
                            title='Relationship'
                            floatingLabel={true} 
                            style={{marginBottom: 10}}
                            value={contact.relationship}
                            setValue={(value)=>handleSetContact('relationship', value, index)}
                            validator={validationGroup.createValidator(() => validateExistence(contact.relationship))}
                         />
                    </Col>
                    <Col lg={6}>
                        <CustomControl
                            maxLength={10} 
                            type='text' 
                            title='Phone Number'
                            floatingLabel={true} 
                            style={{marginBottom: 10}}
                            value={contact.phoneNumber}
                            setValue={(value)=>handleSetContact('phoneNumber', value, index)}
                        />
                    </Col>
                    <Col lg={6}>
                        <CustomControl
                            maxLength={50} 
                            type='text' 
                            title='Email Address'
                            floatingLabel={true} 
                            style={{marginBottom: 10}}
                            value={contact.email}
                            setValue={(value)=>handleSetContact('email', value, index)}
                        />
                    </Col>
                    <div style={{marginBottom: 10}}></div>
                    <AddressAutoFill label='Address' value={contact.address} setValue={(value) => {handleSetContact('address', value, index)}} noRedAsterisk/>
                </Row>
            </Container>
        </Form.Group>
    )
}
