// /*
// __/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____
//  _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__
//   _______\/\\\_____________\/\\\________/\\\/////////\\\_
//    _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_
//     _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_
//      _______\/\\\_____________\/\\\_______\/\\\/////////\\\_
//       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_
//        _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_
//         _______\///______________\///________\///________\///__

//             COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC.
//             ALL RIGHTS RESERVED.
// */

import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Card,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Document, Page } from "react-pdf";
import { DndContext, useDraggable, useDroppable } from "@dnd-kit/core";
import fakeSignature from "../../../assets/fake-signature.png";
import fakeDate from "../../../assets/date.svg";
import CustomButton from "../../../components/CustomButton";
import {
  createCustomOnboardingDocument,
  deleteCustomOnboardingDocument,
  downloadCustomOnboardingDocument,
  updateCustomOnboardingDocument,
} from "../../../services/MyCompanyService";
import { AppGlobals } from "../../../App";
import CustomAlert from "../../../components/CustomAlert";
import { base64ToFile, getBase64FromFile } from "../../../tools";
import AlertModal from "../../../components/AlertModals/AlertModal";
import LoadingWrapper from "../../../components/LoadingWrapper";
import CustomToolTip from "../../../components/CustomToolTip";

export default function MyCompanyOnboardingDocumentUpload({
  selectedCustomOnboardingDocument,
  callBack,
}) {
  const ref = useRef();
  const documentContainerRef = useRef();
  const pagesContainerRef = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [documentName, setDocumentName] = useState(
    selectedCustomOnboardingDocument?.name ?? ""
  );
  const [file, setFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [signatureBoxes, setSignatureBoxes] = useState([]);
  const [selectedSignatureBoxIndex, setSelectedSignatureBoxIndex] = useState();
  const [dateBoxes, setDateBoxes] = useState([]);
  const [selectedDateBoxIndex, setSelectedDateBoxIndex] = useState();
  const SIGNATURE_BOX_WIDTH = 140;
  const SIGNATURE_BOX_HEIGHT = 40;
  const DATE_BOX_WIDTH = 110;
  const DATE_BOX_HEIGHT = 40;
  const PAGE_WIDTH = 612;
  const PAGE_HEIGHT = 792;
  const PAGE_GAP = 16;
  const MARGIN = 8; // Used to make validation stricter

  const currentSignatureBox = useMemo(() => {
    return signatureBoxes[selectedSignatureBoxIndex];
  }, [selectedSignatureBoxIndex, signatureBoxes]);

  const currentDateBox = useMemo(() => {
    return dateBoxes[selectedDateBoxIndex];
  }, [selectedDateBoxIndex, dateBoxes]);

  const elementType = useMemo(() => {
    return currentSignatureBox ? "signature" : currentDateBox ? "date" : null;
  }, [currentSignatureBox, currentDateBox]);

  useEffect(() => {
    if (selectedCustomOnboardingDocument) {
      loadData();
    }
  }, []);

  async function loadData() {
    setIsLoading(true);
    const response = await downloadCustomOnboardingDocument(
      selectedCustomOnboardingDocument.uid
    );
    if (response.status === 200) {
      setFile(base64ToFile(response.base64));
      selectedCustomOnboardingDocument.elements.forEach((el) => el.isValid = true)
      setSignatureBoxes(
        selectedCustomOnboardingDocument.elements.filter(
          (element) => element.type === 1
        )
      );
      setDateBoxes(
        selectedCustomOnboardingDocument.elements.filter(
          (element) => element.type === 2
        )
      );
    } else {
      AppGlobals.alert(
        <CustomAlert type={"danger"} title={response.message} />
      );
    }
    setIsLoading(false);
  }

  function isValidPosition({ x, y, width, height }) {
    const pageIndex = Math.floor(y / (PAGE_HEIGHT + PAGE_GAP));
    const relativeY = y - pageIndex * (PAGE_HEIGHT + PAGE_GAP);
    const isWithinPageBounds =
      Number(x) >= MARGIN &&
      Number(x) + Number(width) <= PAGE_WIDTH - MARGIN &&
      relativeY >= MARGIN &&
      relativeY + Number(height) <= PAGE_HEIGHT - MARGIN;
    return isWithinPageBounds;
  }

  async function handleSubmit() {
    setIsSubmitting(true);
    const document = {
      uid: selectedCustomOnboardingDocument?.uid, 
      name: documentName,
      base64: file.base64,
      elements: [
        ...signatureBoxes.filter((signatureBox) => signatureBox.isValid).map((signatureBox) => ({ ...signatureBox, type: 1 })),
        ...dateBoxes.filter((dateBox) => dateBox.isValid).map((dateBox) => ({ ...dateBox, type: 2 })),
      ],
    };

    const response = selectedCustomOnboardingDocument
      ? await updateCustomOnboardingDocument(document)
      : await createCustomOnboardingDocument(document);
    if (response.status === 200) {
      callBack();
      AppGlobals.alert(<CustomAlert type="success" title={response.message} />);
    } else {
      AppGlobals.alert(
        <CustomAlert type={"danger"} title={response.message} />
      );
    }
    setIsSubmitting(false);
  }

  async function handleDeleteDocument() {
    setIsDeleting(true);
    const response = await deleteCustomOnboardingDocument(selectedCustomOnboardingDocument.uid)
    if (response.status === 200) {
      callBack();
      AppGlobals.alert(<CustomAlert type="success" title={response.message} />);
    } else {
      AppGlobals.alert(
        <CustomAlert type={"danger"} title={response.message} />
      );
    }
    setIsSubmitting(false);
    setIsDeleting(false);
  }

  const handleFileUpload = (e) => {
    getBase64FromFile(e.target.files[0], (value) => {
      e.target.files[0].base64 = value;
    });
    setFile(e.target.files[0]);
    setDateBoxes([]);
    setSignatureBoxes([]);
    setSelectedSignatureBoxIndex(undefined);
    setSelectedDateBoxIndex(undefined);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  function handleDragEnd(event) {
    const { active, over } = event;
  
    if (over && active.id) {
      if (active.id.includes("signature_box_")) {
        const index = active.id.slice(14);
        setSelectedDateBoxIndex(undefined);
        setSelectedSignatureBoxIndex(index);
        setSignatureBoxes((prevState) => {
          const updatedBoxes = [...prevState];
          const updatedBox = {
            ...updatedBoxes[index],
            x: (Number(updatedBoxes[index]?.x) ?? 0) + event.delta.x,
            y: (Number(updatedBoxes[index]?.y) ?? 0) + event.delta.y,
          };
          updatedBox.isValid = isValidPosition(updatedBox);
          updatedBoxes[index] = updatedBox;
          return updatedBoxes;
        });
      }
  
      if (active.id.includes("date_box_")) {
        const index = active.id.slice(9);
        setSelectedSignatureBoxIndex(undefined);
        setSelectedDateBoxIndex(index);
        setDateBoxes((prevState) => {
          const updatedBoxes = [...prevState];
          const updatedBox = {
            ...updatedBoxes[index],
            x: (Number(updatedBoxes[index]?.x) ?? 0) + event.delta.x,
            y: (Number(updatedBoxes[index]?.y) ?? 0) + event.delta.y,
          };
          updatedBox.isValid = isValidPosition(updatedBox);
          updatedBoxes[index] = updatedBox;
          return updatedBoxes;
        });
      }
    }
  }

  function handleSetElement(key, value) {
    if (elementType === "signature") {
      setSignatureBoxes((prevState) => {
        const newSignatureBoxes = [...prevState];
        const newSignatureBox = newSignatureBoxes[selectedSignatureBoxIndex];
        newSignatureBox[key] = value;
        return newSignatureBoxes;
      });
    }

    if (elementType === "date") {
      setDateBoxes((prevState) => {
        const newDateBoxes = [...prevState];
        const newDateBox = newDateBoxes[selectedDateBoxIndex];
        newDateBox[key] = value;
        return newDateBoxes;
      });
    }
  }

  function handleCreateElement(type) {
    if (documentContainerRef.current && pagesContainerRef.current) {
      const documentContainer = documentContainerRef.current;
      const pagesContainer = pagesContainerRef.current;

      const { scrollTop, clientHeight } = documentContainer;
      const { scrollLeft, clientWidth } = pagesContainer;

      const centerX = scrollLeft + clientWidth / 2;
      const centerY = scrollTop + clientHeight / 2;

      if (type === "signature") {
        const newSignatureBox = {
          x: centerX - SIGNATURE_BOX_WIDTH / 2,
          y: centerY - SIGNATURE_BOX_HEIGHT / 2,
          width: SIGNATURE_BOX_WIDTH,
          height: SIGNATURE_BOX_HEIGHT,
          isValid: true,
        };
        setSignatureBoxes((prevBoxes) => [...prevBoxes, newSignatureBox]);
      }

      if (type === "date") {
        const newDateBox = {
          x: centerX - DATE_BOX_WIDTH / 2,
          y: centerY - DATE_BOX_HEIGHT / 2,
          width: DATE_BOX_WIDTH,
          height: DATE_BOX_HEIGHT,
          isValid: true,
        };
        setDateBoxes((prevBoxes) => [...prevBoxes, newDateBox]);
      }
    }
  }

  function handleDeleteElement() {
    if (elementType === "signature") {
      setSignatureBoxes((prevState) => {
        return prevState.toSpliced(selectedSignatureBoxIndex, 1);
      });
    }

    if (elementType === "date") {
      setDateBoxes((prevState) => {
        return prevState.toSpliced(selectedDateBoxIndex, 1);
      });
    }
  }

  const currentBoxInvalid = (currentDateBox && !currentDateBox.isValid) || (currentSignatureBox && !currentSignatureBox.isValid)

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Custom Onboarding Document Editor</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: "hidden", display: 'flex', flexDirection: 'column' }}>
        <LoadingWrapper isLoading={isLoading}>
          <div>
            <input
              ref={ref}
              style={{ display: "none" }}
              type="file"
              accept="application/pdf"
              onChange={handleFileUpload}
            />
            <InputGroup>
              {!selectedCustomOnboardingDocument && (
                <CustomButton
                  onClick={() => {
                    ref.current?.click();
                  }}
                  label={!!document ? "Change File" : "Choose File"}
                />
              )}
              <FloatingLabel label={"Document Name"}>
                <Form.Control
                  type="text"
                  placeholder=""
                  disabled={!file}
                  value={documentName}
                  onChange={(event) => setDocumentName(event.target.value)}
                  maxLength={30}
                />
              </FloatingLabel>
            </InputGroup>
          </div>

          {file && (
            <DndContext onDragEnd={handleDragEnd}>
              <div
                style={{
                  display: "flex",
                  marginTop: 16,
                  flexGrow: 1,
                  overflow: "hidden",
                }}
              >
                <div
                  ref={documentContainerRef}
                  style={{
                    flex: 1,
                    height: "100%",
                    overflow: "auto",
                    display: "flex",
                    justifyContent: "center",
                    background: "gray",
                  }}
                >
                  <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                    <div ref={pagesContainerRef}>
                      <DocumentDroppable
                        dateBoxes={dateBoxes}
                        signatureBoxes={signatureBoxes}
                        numPages={numPages}
                      />
                    </div>
                  </Document>
                </div>

                {/* Side Bar */}
                <div
                  style={{
                    padding: "20px",
                    minWidth: "280px",
                    backgroundColor: "#2c2c2c",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    borderRadius: "8px",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  {/* Action Buttons Section */}
                  <Form.Label
                    style={{
                      textAlign: "center",
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    Add Element
                  </Form.Label>
                  <div style={{ display: "flex", gap: 8 }}>
                    <Button
                      style={{ color: "white" }}
                      disabled={signatureBoxes.length > 4}
                      onClick={() => handleCreateElement("signature")}
                    >
                      Add Signature ({signatureBoxes.length}/5)
                    </Button>

                    <Button
                      style={{ color: "white" }}
                      disabled={dateBoxes.length > 4}
                      onClick={() => handleCreateElement("date")}
                    >
                      Add Date ({dateBoxes.length}/5)
                    </Button>
                  </div>

                  {/* Signature Box Controls */}
                  {elementType && (
                    <Card
                      style={{
                        backgroundColor: "#333",
                        color: "white",
                        width: "100%",
                        padding: "20px",
                        borderRadius: "8px",
                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",
                        marginTop: "16px",
                      }}
                    >
                      <Card.Body>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: 'center',
                            marginBottom: 16
                          }}
                        >
                          <h6
                          >
                            Adjust Dimensions
                          </h6>
                          <div style={{display: 'flex', alignItems: 'center', gap: 8}}>
                          <FontAwesomeIcon
                            onClick={handleDeleteElement}
                            style={{
                              color: "var(--bs-danger)",
                              cursor: "pointer",
                            }}
                            icon={faTrash}
                            />
                            </div>
                        </div>
                        {/* Height Control */}
                        <div style={{ marginBottom: "16px" }}>
                          <Form.Label style={{ fontSize: "0.95rem" }}>
                            Height
                          </Form.Label>
                          <Form.Range
                            style={{ width: "100%" }}
                            min={20}
                            max={100}
                            step={1}
                            value={
                              currentSignatureBox?.height ??
                              currentDateBox?.height
                            }
                            onChange={(event) =>
                              handleSetElement("height", event.target.value)
                            }
                          />
                        </div>

                        {/* Width Control */}
                        <div>
                          <Form.Label style={{ fontSize: "0.95rem" }}>
                            Width
                          </Form.Label>
                          <Form.Range
                            style={{ width: "100%" }}
                            min={50}
                            max={500}
                            step={1}
                            value={
                              currentSignatureBox?.width ??
                              currentDateBox?.width
                            }
                            onChange={(event) =>
                              handleSetElement("width", event.target.value)
                            }
                          />
                        </div>
                        { !!currentBoxInvalid &&
                          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 18}}>
                            <CustomToolTip color={"var(--bs-danger)"} text={"Current selected element is outside allowed constraints of document. If not fixed, element will be deleted on save."}/>
                          </div>
                        }
                      </Card.Body>
                    </Card>
                  )}
                </div>
              </div>
            </DndContext>
          )}
        </LoadingWrapper>
      </Modal.Body>
      <Modal.Footer>
        {!!selectedCustomOnboardingDocument && (
          <CustomButton
            label={"Delete"}
            variant="outline-danger"
            onClick={() => setShowDeleteModal(true)}
          />
        )}
        <CustomButton
          isLoading={isSubmitting}
          disabled={
            !documentName || (dateBoxes.length < 1 && signatureBoxes.length < 1)
          }
          label={"Submit"}
          onClick={handleSubmit}
        />
      </Modal.Footer>
      <AlertModal
        centered
        show={showDeleteModal}
        onHide={() => {
          setShowDeleteModal(false);
        }}
        isLoading={isDeleting}
        variant="outline-danger"
        title="Delete Custom PDF?"
        message="Are you sure you want to delete this custom onboarding PDF? This cannot be undone."
        buttonLabel="Delete"
        callBack={handleDeleteDocument}
      />
    </>
  );
}

function DocumentDroppable({ signatureBoxes, dateBoxes, numPages }) {
  const { setNodeRef } = useDroppable({
    id: "droppable-area",
  });

  return (
    <div
      ref={setNodeRef}
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 16,
        gap: 16,
        position: "relative",
      }}
    >
      {signatureBoxes.map((box, i) => (
        <SignatureBox key={i} box={box} index={i} />
      ))}
      {dateBoxes.map((box, i) => (
        <DateBox key={i} box={box} index={i} />
      ))}
      {Array.from(new Array(numPages), (_, index) => (
        <div
          key={`page_container_${index + 1}`}
          style={{ position: "relative" }}
        >
          <Page pageNumber={index + 1} />
        </div>
      ))}
    </div>
  );
}

function SignatureBox({ box, index }) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: `signature_box_${index}`,
  });

  // Compute the draggable transform style if applicable
  const transformStyle = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : {};

  return (
    <div
      ref={setNodeRef}
      style={{
        position: "absolute",
        zIndex: 1000,
        left: Number(box.x),
        top: Number(box.y),
        width: Number(box.width),
        height: Number(box.height),
        cursor: "move",
        border: box.isValid ? "2px dashed gray" : "2px dashed red",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        ...transformStyle,
      }}
      {...listeners}
      {...attributes}
    >
      <img
        src={fakeSignature}
        alt="Signature"
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          objectFit: "contain",
        }}
      />
    </div>
  );
}

function DateBox({ box, index }) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: `date_box_${index}`,
  });

  const transformStyle = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : {};

  return (
    <div
      ref={setNodeRef}
      style={{
        position: "absolute",
        zIndex: 1000,
        left: Number(box.x),
        top: Number(box.y),
        width: Number(box.width),
        height: Number(box.height),
        cursor: "move",
        border: box.isValid ? "2px dashed gray" : "2px dashed red",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        ...transformStyle,
      }}
      {...listeners}
      {...attributes}
    >
      <img
        src={fakeDate}
        alt="Signature"
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          objectFit: "contain",
        }}
      />
    </div>
  );
}
