/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import CustomButton from '../../../components/CustomButton';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import FileControl from '../../../components/FileControl';
import { createCompanyDocument } from '../../../services/MyCompanyService';
import SwitchControl from '../../../components/SwitchControl';

export default function MyCompanyDocumentUpload({handleDocumentCrud}) {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [document, setDocument] = useState({});
    const [isRequired, setIsRequired] = useState(false);

    const handleSubmit = async () => {
        setIsSubmitting(true);

        const newDocument = {
            name: document.name,
            type: document.type,
            base64: document.base64,
            dateCreated: moment().format('YYYY-MM-DD'),
            isRequired: isRequired,
            acknowledgements: [],
        }

        const response = await createCompanyDocument(newDocument);
        if (response.status === 200) {
            newDocument.uid = response.uid;
            handleDocumentCrud('create', newDocument);
        }
        
        setIsSubmitting(false);
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Upload Document</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <FileControl document={document} setDocument={setDocument}/>
                <SwitchControl title='Requires Employee Acknowledgement (.pdf files only)' disabled={document.type !== 'pdf'} value={isRequired} setValue={setIsRequired}/>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton isLoading={isSubmitting} label='Upload Acknowledgement Document' onClick={handleSubmit}/>
            </Modal.Footer>
        </>
          
    );
}