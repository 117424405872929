/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import CustomControl from "../../../../../../components/CustomControl";
import Modal from 'react-bootstrap/Modal';
import { holidayPayTypeDictionary} from "../../../../payrollTools";
import { validateExistence, ValidationGroup, validateGreaterThanZero } from "../../../../../../validation";
import { Col, Container, Row } from "react-bootstrap";
import Picker from "../../../../../../components/Picker";
import Holiday from "../../../../Models/Holiday";


export default function AddHolidayForm({handleAddHoliday, qualifiesForPtoAndHolidays}) {
    const [payType, setPayType] = useState('');
    const [payRate, setPayRate] = useState('');
    const [unitsWorked, setUnitsWorked] = useState('');
   
    const validationGroup = new ValidationGroup();

    const handleSubmit = () => {
        if (validationGroup.isValid()) {
            handleAddHoliday(new Holiday(payType, payRate, unitsWorked));
        }
    }

    const content = (
        <>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <Picker title='Pay Type' validator={validationGroup.createValidator(() => validateExistence(payType))} selection={payType} setSelection={(value) => {setPayType(value)}} itemNames={Object.values(holidayPayTypeDictionary)} itemValues={Object.keys(holidayPayTypeDictionary)}/>
                <CustomControl floatingLabel type='number' validator={validationGroup.createValidator(() => validateGreaterThanZero(payRate))} value={payRate} setValue={setPayRate} onChange={(event) => {setPayRate(event.target.value)}} title='Pay Rate' />
                {payType != 'flat' &&  
                    <CustomControl floatingLabel type='number' validator={validationGroup.createValidator(() => validateExistence(unitsWorked))} value={unitsWorked} setValue={setUnitsWorked} onChange={(event) => {setUnitsWorked(event.target.value)}} title='Holiday Units' />
                }
            </Modal.Body>
            <Modal.Footer style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button style={{color: 'white'}} disabled={!validationGroup.isValid()} onClick={handleSubmit}>Submit</Button>
            </Modal.Footer>
        </>
    )

    return qualifiesForPtoAndHolidays() ? content : (
        <span style={{fontStyle: 'italic', color: 'gray', textAlign: 'center', margin: 8}}>Salaried employees do not receive pay for holidays and pto</span>
    )
            
}