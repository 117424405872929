/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import { Button, Form, InputGroup, ListGroup, Modal } from "react-bootstrap";
import OfferLetterEditor from "../../components/OfferLetterEditor";
import CustomButton from "../../components/CustomButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import AlertModal from "../../components/AlertModals/AlertModal";
import { AppGlobals } from "../../App";
import CustomAlert from "../../components/CustomAlert";
import {
  createOfferLetter,
  deleteOfferLetter,
  updateOfferLetter,
} from "../../services/MyCompanyService";
import { checkPermission } from "../../tools";
import { PDFViewer } from "@react-pdf/renderer";
import OfferLetterPdf from "../HumanResources/HRDocuments/OfferLetterPdf";

export default function MyCompanyOfferLetters({
  offerLetters,
  setOfferLetters,
}) {
  const [selectedOfferLetter, setSelectedOfferLetter] = useState(undefined);
  const [showEditor, setShowEditor] = useState(false);

  function updateOfferLetterState(offerLetter) {
    setOfferLetters([
      ...offerLetters.filter((o) => o.uid != offerLetter.uid),
      offerLetter,
    ]);
    if (selectedOfferLetter) {
      setSelectedOfferLetter(offerLetter);
    } else {
      setShowEditor(false);
    }
  }

  function handleRemoveOfferLetter(uid) {
    setOfferLetters(offerLetters.filter((o) => o.uid != uid));
    setShowEditor(false);
  }

  const offerLetterItems = offerLetters.map((ol) => {
    return (
      <ListGroup.Item
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
        action
        key={ol.uid}
        onClick={() => {
          setSelectedOfferLetter(ol);
          setShowEditor(true);
        }}
      >
        <p style={{ margin: 0 }}>{ol.name}</p>
        {ol.defaultForTitle && (
          <p style={{ margin: 0 }}>
            <b>Default: </b>
            {ol.defaultForTitle}
          </p>
        )}
      </ListGroup.Item>
    );
  });

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Company Offer Letter Templates</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          {offerLetterItems}
          {checkPermission("offerLetter") && (
            <ListGroup.Item
              action
              onClick={() => {
                setSelectedOfferLetter(undefined);
                setShowEditor(true);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "var(--bs-primary)",
              }}
            >
              <span>Create Offer Letter Template</span>
              <FontAwesomeIcon icon={faChevronRight} />
            </ListGroup.Item>
          )}
        </ListGroup>
      </Modal.Body>
      <Modal
        show={showEditor}
        onHide={() => {
          setShowEditor(false);
        }}
        fullscreen
      >
        <MyCompanyOfferLetterEditor
          selectedOfferLetter={selectedOfferLetter}
          updateOfferLetterState={updateOfferLetterState}
          handleRemoveOfferLetter={handleRemoveOfferLetter}
        />
      </Modal>
    </>
  );
}

function MyCompanyOfferLetterEditor({
  selectedOfferLetter,
  updateOfferLetterState,
  handleRemoveOfferLetter,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [content, setContent] = useState(selectedOfferLetter?.content ?? "");
  const [name, setName] = useState(selectedOfferLetter?.name ?? "");
  const [showDelete, setShowDelete] = useState(false);
  const [showFinalize, setShowFinalize] = useState(false);

  async function handleSubmit() {
    setIsSubmitting(true);
    const newOfferLetter = {
      uid: selectedOfferLetter?.uid,
      name: name,
      content: content,
    };

    let response;
    if (selectedOfferLetter) {
      response = await updateOfferLetter(newOfferLetter);
    } else {
      response = await createOfferLetter(newOfferLetter);
    }

    if (response.status === 200) {
      if (selectedOfferLetter) {
        updateOfferLetterState(newOfferLetter);
      } else {
        newOfferLetter.uid = response.uid;
        updateOfferLetterState(newOfferLetter);
      }
    } else {
      AppGlobals.alert(<CustomAlert type="danger" title={response.message} />);
    }
    setIsSubmitting(false);
  }

  async function handleDelete() {
    setIsDeleting(true);
    const response = await deleteOfferLetter(selectedOfferLetter.uid);
    if (response.status === 200) {
      handleRemoveOfferLetter(selectedOfferLetter.uid);
      setShowDelete(false);
    } else {
      AppGlobals.alert(<CustomAlert type="danger" title={response.message} />);
    }
    setIsDeleting(false);
  }

  const isSaved =
    content === selectedOfferLetter?.content &&
    name === selectedOfferLetter?.name;

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          {checkPermission("offerLetter")
            ? "Editing Offer Letter Template"
            : "Viewing Offer Letter Template"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showFinalize ? (
          <PDFViewer height={1150} width={"100%"}>
            <OfferLetterPdf offerLetterData={{ content: content }} />
          </PDFViewer>
        ) : (
          <OfferLetterEditor value={content} setValue={setContent} />
        )}
      </Modal.Body>
      {checkPermission("offerLetter") && (
        <>
          <Modal.Footer>
            {!showFinalize ? (
              <>
                {selectedOfferLetter && (
                  <Button
                    style={{
                      border: "none",
                      background: "none",
                      color: "red",
                      boxShadow: "none",
                    }}
                    onClick={() => setShowDelete(true)}
                  >
                    Delete Template
                  </Button>
                )}
                <InputGroup style={{ maxWidth: 600, flexWrap: "nowrap" }}>
                  <InputGroup.Text>Template Name</InputGroup.Text>
                  <Form.Control
                    maxLength={30}
                    value={name}
                    isInvalid={!name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </InputGroup>
                <CustomButton
                  label="Finalize"
                  disabled={isSaved || !name}
                  onClick={() => setShowFinalize(true)}
                />
              </>
            ) : (
              <>
                <CustomButton onClick={() => setShowFinalize(false)} label={<><FontAwesomeIcon style={{marginRight: 4}} icon={faChevronLeft}/><span>Go Back</span></>}/>
                <CustomButton isLoading={isSubmitting} onClick={handleSubmit} label="Submit"/>
              </>
            )}
          </Modal.Footer>

          <AlertModal
            show={showDelete}
            onHide={() => setShowDelete(false)}
            isLoading={isDeleting}
            variant="outline-danger"
            title="Delete Offer Letter Template?"
            message="This cannot be undone."
            buttonLabel="Delete Template"
            callBack={handleDelete}
          />
        </>
      )}
    </>
  );
}
