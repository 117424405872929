import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap'
import CustomButton from '../../../components/CustomButton'
import CustomControl from '../../../components/CustomControl';
import { createLocations, updateLocation } from '../../../services/MyCompanyService';
import { AddressValidator, ValidationGroup, validateEin, validateExistence, validatePhoneNumber, validateSsn } from '../../../validation';
import AddressControl from '../../../components/AddressControl';
import AddressAutoFill from '../../../components/AddressAutoFill';

export default function LocationCreator({location, setSelectedLocation, setShowCreatorModal, loadData}) {
    
    const [locationsArray, setLocationsArray] = useState([{name: '', address: undefined, phoneNumber: '', ein: ''}]);
    const [formIsValid, setFormIsValid] = useState(false);

    function handleSetLocation(key, value){
        const newLocation = structuredClone(location);
        newLocation[key] = value;
        setSelectedLocation(newLocation);  
    }

    function handleSubmit(){
      const encodedData = locationsArray.map((location) => ({
        ...location,
        phoneNumber: location.phoneNumber ? location.phoneNumber : null,
        ein: location.ein ? location.ein : null
      }))
        createLocations(encodedData);
        loadData();
        setShowCreatorModal(false);
    }

    function handleAddLocation(){
      setLocationsArray([...locationsArray, {name: '', address: undefined}]);
    }

    function handleSetLocation(key, value, index){
      const newLocationsArray = structuredClone(locationsArray);
      const newLocation = newLocationsArray[index];
      newLocation[key] = value;
      setLocationsArray(newLocationsArray);
    }

    function handleRemoveLocation(deleteIndex){
      const newLocationsArray = structuredClone(locationsArray.toSpliced(deleteIndex, 1));
      setLocationsArray(newLocationsArray);
    }

  return (
    <>
      <Modal.Header closeButton>
          <Modal.Title>{'Creating Locations'}</Modal.Title>               
      </Modal.Header>
      <Modal.Body>
        {locationsArray.map((location, index)=>{
          return <LocationCreatorForm key={index} location={location} index={index} handleSetLocation={handleSetLocation} handleRemoveLocation={handleRemoveLocation} setFormIsValid={setFormIsValid}/>
        })}
        <Button style={{width: '100%', marginTop: 12}} variant={'outline-primary'} onClick={handleAddLocation}>Add Another Location</Button>
      </Modal.Body>
      <Modal.Footer>
          <CustomButton disabled={!formIsValid} label={'Submit Locations'} onClick={handleSubmit}/>
      </Modal.Footer>
  </>
  )
}


function LocationCreatorForm({location, index, handleSetLocation, setFormIsValid, handleRemoveLocation}) {

  const validationGroup = new ValidationGroup();
  const addressValidator = new AddressValidator(location?.address);

  useEffect(()=>{
    setFormIsValid(validationGroup.isValid() && addressValidator.isValid());
}, [location])

  return (
    <Container fluid>
        <Row style={{marginBottom: 24}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 12, marginTop: 30, gap: 16}}>
                <h3 style={{}}>Location #{index+1}</h3>
                <Button onClick={()=>handleRemoveLocation(index)} variant='outline-danger'>Remove Location</Button>
            </div>
            <Col lg={12}>
                <CustomControl
                    maxLength={50}
                    floatingLabel={true}
                    title='Location Name'
                    style={{marginBottom: 24}}
                    value={location.name}
                    setValue={(value)=>handleSetLocation('name', value, index)}
                    validator={validationGroup.createValidator(() => validateExistence(location.name))}
                />
                <CustomControl
                  maxLength={10}
                  floatingLabel={true}
                  title='Phone Number'
                  style={{marginBottom: 24}}
                  value={location.phoneNumber}
                  setValue={(value)=>handleSetLocation('phoneNumber', value, index)}
                  validator={validationGroup.createValidator(() => location.phoneNumber ? validatePhoneNumber(location.phoneNumber) : undefined)}
                />
                <CustomControl
                  maxLength={9}
                  floatingLabel={true}
                  title='Ein'
                  style={{marginBottom: 24}}
                  value={location.ein}
                  setValue={(value)=>handleSetLocation('ein', value, index)}
                  validator={validationGroup.createValidator(() => location.ein ? validateEin(location.ein) : undefined)}
                />
                <AddressAutoFill label='Address' value={location.address} setValue={(value)=> handleSetLocation('address', value, index)} validator={addressValidator}/>
            </Col>
        </Row>
      </Container>
  )
}
