/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Big from "big.js";
import { validateDecimal, validateInteger } from "../payrollTools";
import Deduction from "./Deduction";
import Decoder from "../../../decoding";
import PayrollEntryWeek from "./PayrollEntryWeek";
import Loan from "./Loan";
import { usdFormatter } from "../../../tools";
import moment from "moment";

export default class PayrollEntry {
    userIdentifier; //string
    firstName; //string
    middleName; //string
    lastName; //string
    weeks;
    title; //string
    medical; //string
    dental; //string
    vision; //string
    childSupport; //[string]
    loans; //[Loan]
    deductions; //[Deductions]
    location;
    notes; //string
    reoccuringNotes; //boolean
    ptoBalance; //integer
    isNew; //bool

    periodStart; //string
    periodEnd; //string

    status; //string
    ptoAccrual;
    ptoAccrualType;
    
    // only used for state handling
    weekIndex = 0;


    constructor(
        userIdentifier, 
        firstName, 
        middleName, 
        lastName, 
        weeks,
        title, 
        medical, 
        dental, 
        vision, 
        childSupport, 
        loans,
        deductions,
        location,
        notes, 
        reoccuringNotes, 
        ptoBalance,
        isNew, 
        periodStart, 
        periodEnd, 
        status,
        ptoAccrual,
        ptoAccrualType
    ) {
        this.userIdentifier = userIdentifier;
        this.firstName = firstName;
        this.middleName = middleName;
        this.lastName = lastName;
        this.weeks = weeks;
        this.title = title;
        this.medical = medical;
        this.dental = dental;
        this.vision = vision;
        this.childSupport = childSupport;
        this.loans = loans;
        this.deductions = deductions;
        this.location = location;
        this.notes = notes;
        this.reoccuringNotes = reoccuringNotes;
        this.ptoBalance = ptoBalance;
        this.isNew = isNew;
        this.periodStart = periodStart;
        this.periodEnd = periodEnd;
        this.status = status;
        this.ptoAccrual = ptoAccrual;
        this.ptoAccrualType = ptoAccrualType;
    }

    static initDefault() {
        return new PayrollEntry(-1, 'ERR', 'ERR', 'ERR', [], 'ERR', 0, 0, 0, [], [], [], {}, '', false, 0, false, '', '', 'none', 0, 0);
    }

    static decodeArray(jsonStringArray) {
        let jsonArray = JSON.parse(jsonStringArray);
        return jsonArray.map(obj => PayrollEntry.decode(obj)).sort(PayrollEntry.sort);
    }

    static decode(json) {
        const decoder = new Decoder(json);
        const userIdentifier = decoder.decode('userIdentifier', Decoder.UidString);
        const firstName = decoder.decode('firstName', Decoder.StringStrict);
        const middleName = decoder.decode('middleName', Decoder.StringStrict, {defaultValue: '', warn: false});
        const lastName = decoder.decode('lastName', Decoder.StringStrict);
        const weeks = decoder.decode('weeks', Decoder.Array).map(w => PayrollEntryWeek.decode(w));
        const title = decoder.decode('title', Decoder.StringStrict);
        const medical = decoder.decode('medical', Decoder.Decimal);
        const dental = decoder.decode('dental', Decoder.Decimal);
        const vision = decoder.decode('vision', Decoder.Decimal);
        const childSupport = decoder.decode('childSupport', Decoder.DecimalArray, {defaultValue: [], warn: true});
        const loans = decoder.decode('loans', Decoder.Array).map(l => Loan.decode(l));
        const deductions = decoder.decode('deductions', Decoder.Array).map(d => Deduction.decode(d));
        const location = json.location //address this later
        const notes = decoder.decode('notes', Decoder.StringStrict, {defaultValue: '', warn: true});
        const reoccuringNotes = decoder.decode('reoccuringNotes', Decoder.Boolean, {defaultValue: false, warn: false});
        const ptoBalance = decoder.decode('ptoBalance', Decoder.Decimal, {defaultValue: 0, warn: true});
        const isNew = decoder.decode('isNew', Decoder.Boolean, {defaultValue: false, warn: true});
        const periodStart = decoder.decode('periodStart', Decoder.Date);
        const periodEnd = decoder.decode('periodEnd', Decoder.Date);
        const status = decoder.decode('status', Decoder.StringStrict, {defaultValue: '', warn: true});
        const ptoAccrual = decoder.decode('ptoAccrual', Decoder.Decimal, {defaultValue: 0, warn: false});
        const ptoAccrualType = decoder.decode('ptoAccrualType', Decoder.Integer, {defaultValue: 0, warn: false});

        if (decoder.checkForErrors()) {
            return new PayrollEntry(
                userIdentifier,
                firstName,
                middleName,
                lastName,
                weeks,
                title,
                medical,
                dental,
                vision,
                childSupport,
                loans,
                deductions,
                location,
                notes,
                reoccuringNotes,
                ptoBalance,
                isNew,
                periodStart,
                periodEnd,
                status,
                ptoAccrual,
                ptoAccrualType
            )
        } else {
            return PayrollEntry.initDefault();
        }

    }

    encode() {
        return {
            userIdentifier: this.userIdentifier,
            firstName: this.firstName,
            middleName: this.middleName,
            lastName: this.lastName,
            weeks:this.weeks.map((week)=>week.encode()),
            title: this.title,
            medical: validateDecimal(this.medical),
            dental: validateDecimal(this.dental),
            vision: validateDecimal(this.vision),
            childSupport: this.childSupport.map(cs => validateDecimal(cs)),
            loans: this.loans.map(l => l.encode()),
            deductions: this.deductions.map(d => d.encode()),
            location: this.location,
            notes: this.notes,
            reoccuringNotes: this.reoccuringNotes,
            isNew: this.isNew,
            periodStart: this.periodStart,
            periodEnd: this.periodEnd,
            status: this.status,
            ptoAccrual: validateDecimal(this.ptoAccrual),
            ptoAccrualType: validateInteger(this.ptoAccrualType)
        }
    }

    static encodeArray(entriesArray) {
        const encodedArray = entriesArray.map(entry => entry.encode());
        return JSON.stringify(encodedArray);
    }

    static sort(a, b) {
        if (a.location.name < b.location.name) {
            return -1;
        } else if (a.location.name > b.location.name) {
            return 1;
        } else {
            if (a.isNew !== b.isNew) {
                if (a.isNew) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (a.name() < b.name()) {
                    return -1;
                } else if (a.name() > b.name()) {
                    return 1;
                } else {
                    return 0;
                }
            }
        }
    }

    name() {
        return this.lastName + ', ' + this.firstName + ' ' + (this.middleName ?? '');
    }
    
    qualifiesForPtoAndHolidays() {
        return this.weeks.find(week => week.pay.find(p => p.payType == 0));
    }
    
    gross() {
        return this.weeks.reduce((prev, curr) => prev.plus(curr.getSubGross()), new Big('0.00'));
    }

    getColumnInclusion() {

        const weeklyColumnInclusion = this.weeks.map(w => w.getColumnInclusion());
        const includeOvertimeColumns = weeklyColumnInclusion.find(ci => ci.includeOvertimeColumns) !== undefined;
        const includeSalary = !includeOvertimeColumns && weeklyColumnInclusion.find(ci => ci.includeSalary);
        const includeDaily = !includeOvertimeColumns && weeklyColumnInclusion.find(ci => ci.includeDaily);

        return {
            includeSalary: includeSalary,
            includeDaily: includeDaily,
            includeOvertimeColumns: includeOvertimeColumns,
            includeHourly: includeOvertimeColumns || weeklyColumnInclusion.find(ci => ci.includeHourly),
            includePto: weeklyColumnInclusion.find(ci => ci.includePto) !== undefined,
            includeRegularWages: includeSalary || includeDaily,
            includeHolidayWages:  weeklyColumnInclusion.find(ci => ci.includeHolidayWages) !== undefined,
            inlcudeChildSupport: this.childSupport.find(cs => parseFloat(cs) !== 0.0),
            includeMedical: validateDecimal(this.medical) !== 0.0,
            includeDental: validateDecimal(this.dental) !== 0.0,
            includeVision: validateDecimal(this.vision) !== 0.0,
            includeHolidayBonus: weeklyColumnInclusion.find(ci => ci.includeHolidayBonus) !== undefined,
            includeOtherDiscretionaryBonus: weeklyColumnInclusion.find(ci => ci.includeOtherDiscretionaryBonus) !== undefined,
            includeLoans: this.loans.length > 0,
        }
    }

    getDeductionColumnInclusion() {
        const inclusion = new Set();
        Deduction.deductionTypes.forEach((type) => {
            if (this.deductions.find(d => d.type === type)) {
                inclusion.add(type);
            }
        });
        return inclusion;
    }

    getReducedPay() {

        const reducedPay = [];

        this.weeks.forEach((week) => {
            week.pay.filter(p => parseInt(p.payType) >= 0).forEach((pay) => {
                const match = reducedPay.find(p => p.payType == pay.payType && p.payRate === pay.payRate);
                if (match) {
                    if (pay.payType == 0) {
                        match.unitsWorked += pay.unitsWorked;
                    } else {
                        match.payEnd = pay.payEnd;
                    }
                } else {
                    reducedPay.push(pay.duplicate());
                }
            })
        });

        return reducedPay;
    }

    getNotesForSpreadsheet() {
        let str = '';
        if (this.getEnabledLoans().length > 0) {
            str += 'Loans: ';
            this.getEnabledLoans().forEach((l, index) => {
                if (index > 0) {
                    str += ', ';
                }
                str += `${l.name} - ${usdFormatter.format(l.getAmount())}`
            });
            str += '\n';
        }

        const termination = this.weeks.reduce((prev, curr) => {
            return prev ?? curr.pay.find(p => parseInt(p.payType) < 0);
        }, null);
        if (termination) {
            str += `Terminated ${moment(termination.payStart).format('MMM D')} - ${moment(termination.payEnd).format('MMM D')}`;
            str += '\n';
        }

        const firstPay = this.weeks.reduce((prev, curr) => {
            return prev ?? curr.pay[0];
        }, null);
        if (firstPay?.payStart > this.periodStart) {
            str += `Hired ${moment(this.weeks[0].pay[0]?.payStart).format('MMM D')}`;
            str += '\n';
        }

        return str + this.notes;
    }

    getPtoHoursAccrued() {
        if (this.ptoAccrual > 0) {
            if (this.ptoAccrualType == 0) {
                const hoursWorked = this.weeks.filter(w => w.payType == 0).reduce((prev, curr) => {
                    return prev + curr.hoursWorked;
                }, 0.0);
                return (hoursWorked / 40.0) * this.ptoAccrual;
            } else {
                return this.ptoAccrual;
            }
        } else {
            return 0;
        }
    }


    /////////////////////////
    //For PayrollPeriod Files
    /////////////////////////

    totalRegularWages() {
        return this.weeks.reduce((prev, curr) => {
            if (curr.qualifiesForFLSA()) {
                return prev;
            } else {
                return prev.plus(curr.pay.filter(p => parseInt(p.payType) > 0).reduce((prevPay, currPay) => {
                    return prevPay.plus(currPay.getWages());
                }, new Big(0.0)));
            }
        }, new Big(0.0));
    }

    totalHourlyWages() {
        return this.weeks.reduce((prev, curr) => {
            if (curr.qualifiesForFLSA()) {
                return prev.plus(curr.hourlyWages());
            } else {
                return prev.plus(curr.pay.filter(p => p.payType == 0).reduce((prevPay, currPay) => {
                    return prevPay.plus(currPay.getWages());
                }, new Big(0.0)));
            }
        }, new Big(0.0));
    }

    totalOvertimeWages() {
        return this.weeks.reduce((prev, curr) => {
            if (curr.qualifiesForFLSA()) {
                return prev.plus(curr.overtimeWages());
            } else {
                return prev;
            }
        }, new Big(0.0));
    }

    pto() {
        return this.weeks.reduce((prev, curr) => { return prev + curr.ptoHours() }, 0);
    }

    ptoWages() {
        return this.weeks.reduce((prev, curr)=>{
            return prev.plus(curr.ptoWages());
        }, new Big('0.00'));
    }

    holidayWages() {
        return this.weeks.reduce((prev, curr)=>{
            return prev.plus(curr.holidayWages());
        }, new Big('0.00'));
    }

    getAllAdditionalPay() {
        let array = [];
        this.weeks.forEach((week) => {
            array = array.concat(week.additionalPay);
        })
        return array;
    }

    getEnabledLoans() {
        return this.loans.filter(l => l.isEnabled);
    }

    totalChildSupport() {
        return this.childSupport.reduce((prev, curr) => {return prev + validateDecimal(curr)}, 0.0);
    }

    totalDeductionsByType() {
        const totals = {};
        Deduction.deductionTypes.filter(t => t !== '401K (% of Gross)').forEach((type) => {
            totals[type] = this.deductions.filter(d => d.type === type).reduce((prev, curr) => {
                return prev + curr.getAmount();
            }, 0.0);
        });
        return totals;
    }
}



