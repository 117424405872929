import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { getLocations } from '../../../services/MyCompanyService';
import { addressToString, formatEin, formatPhoneNumber } from '../../../tools';
import PageSpinner from "../../../components/PageSpinner";
import LocationEditor from './LocationEditor';
import LocationCreator from './LocationCreator';
import Cookies from 'universal-cookie';

export default function MyCompanyLocations() {

    const [isLoading, setIsLoading] = useState(false);
    const [showInactiveItems, setShowInactiveItems] = useState(false);
    const [locations, setLocations] = useState();
    const [showEditorModal, setShowEditorModal] = useState(false);
    const [showCreatorModal, setShowCreatorModal] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const showExtraLocationData = Boolean(new Cookies().get("locationsAsCompaniesForOnboarding"));

    const sortLocations = (a, b) => {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    }   

    const loadData = async () => {
        setIsLoading(true)
        const response = await getLocations();
        if (response.status == '200') {
            setLocations(response.locations.sort(sortLocations));
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadData();
    }, [])

    const locationRows = locations?.filter(location => (showInactiveItems || location.isEnabled == 1)).map((location) => {
    return (
        
        <tr key={location.uid} style={location.isEnabled == 1 ? {} : {opacity: 0.5}} className='cursor-pointer' onClick={() => {setSelectedLocation(location); setShowEditorModal(true);}}>
            <td>{location.name + (location.isEnabled === 1 ? '' : ' (inactive)')}</td>
            <td>{addressToString(location.address)}</td>
            {
                showExtraLocationData && 
                <>
                    <th>{location.phoneNumber ? formatPhoneNumber(location.phoneNumber) : "-"}</th>
                    <th>{location.ein ? formatEin(location.ein) : "-"}</th>
                </>
            }
        </tr>
    )
    });

    const locationTable = (
    <Table responsive striped bordered hover>
        <thead>
            <tr>
                <th>Name</th>
                <th>Terminal Address</th>
                {
                    showExtraLocationData && 
                    <>
                        <th>Phone Number</th>
                        <th>EIN</th>
                    </>
                }
            </tr>
        </thead>
        <tbody>
            {locationRows}
        </tbody>
    </Table>
    );

    const content = (
    <div>
        <div style={{display: 'flex', marginBottom: 12, justifyContent: 'space-between', alignItems: 'center'}}>
            <Form.Check style={{flexShrink: 0}} type='switch' label='Show inactive locations' checked={showInactiveItems} onChange={() => {setShowInactiveItems(!showInactiveItems)}}/>
            <div style={{display: 'flex', gap: 8}}>
                <Button onClick={()=>setShowCreatorModal(true)} variant={'outline-primary'}>
                    Add Locations
                </Button>
            </div>
        </div>

        {locationTable}

        <Modal show={showEditorModal} onHide={() => {setShowEditorModal(false)}} centered size='lg'>
            <LocationEditor setSelectedLocation={setSelectedLocation} location={selectedLocation} setShowEditorModal={setShowEditorModal} loadData={loadData}/>
        </Modal>
        <Modal show={showCreatorModal} onHide={()=>{setShowCreatorModal(false)}} centered size='lg'>
            <LocationCreator setSelectedLocation={setSelectedLocation} location={selectedLocation} setShowCreatorModal={setShowCreatorModal} loadData={loadData}/>
        </Modal>
    </div>
    )
    return (
    <>
     {isLoading ? <PageSpinner/> : content}
    </>
    )

}
