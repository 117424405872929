/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import moment from "moment";
import Modal from 'react-bootstrap/Modal';
import { ListGroup } from "react-bootstrap";
import CustomTabs from "../../../components/CustomTabs";
import CustomButton from "../../../components/CustomButton";
import { adminMode } from "../payrollTools";
import { generatePayrollEntries as generatePayrollEntriesClient } from "../../../services/PayrollServiceClient";
import { generatePayrollEntries as generatePayrollEntriesAdmin } from "../../../services/PayrollServiceMtb";
import PayrollEntry from "../Models/PayrollEntry";


export default function PayrollEmployeeSelector({payrollIdentifier, entries, handleSetEntries, employeeFilter, setEmployeeFilter, employees}) {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [listSwitch, setListSwitch] = useState(0);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const handleSubmit = async () => {
        setIsSubmitting(true);
        const response = adminMode ? await generatePayrollEntriesAdmin(payrollIdentifier, selectedUsers) : await generatePayrollEntriesClient(payrollIdentifier, selectedUsers);
        if (response.status === 200) {
            const newEntries = PayrollEntry.decodeArray(response.entries);
            handleSetEntries(entries.concat(newEntries), false);
            setEmployeeFilter(employeeFilter.concat(newEntries.map(e => e.userIdentifier)));
            setListSwitch(0);
        }
        setIsSubmitting(false);
    }


    const handleSelectEmployee = (userIdentifier) =>{
        let newArr = Array.from(selectedUsers);
        if (newArr.includes(userIdentifier)){
            newArr = newArr.filter((i) => i != userIdentifier);
        } else {
            newArr.push(userIdentifier);
        }
        setSelectedUsers(newArr);
    }

    const handleSetEmployeeFilter = (userIdentifier) => {
        let newArray = Array.from(employeeFilter);
        if (employeeFilter.includes(userIdentifier)) {
            newArray = newArray.filter(r => r !== userIdentifier);
        } else {
            newArray.push(userIdentifier);
        }
        setEmployeeFilter(newArray);
    }

    
   
    const rows = entries.map((entry) => {
        const isSelected = employeeFilter.includes(entry.userIdentifier);
        
        return (
            <ListGroup.Item style={{display: 'flex', justifyContent: 'space-between', gap: 6}} key={entry.userIdentifier} action active={isSelected} onClick={() => {handleSetEmployeeFilter(entry.userIdentifier)}}>
                <span>{entry.lastName + ', ' + entry.firstName + ' ' +(entry.middleName ? entry.middleName.substring(0,1) : '')}</span>
                <span>{entry.csaName}</span>
            </ListGroup.Item>
        )
    });

  

    const usersNotInEntries = employees.sort((a,b)=>a.lastName.localeCompare(b.lastName)).filter((user) => !entries.find((e) => e.userIdentifier === user.uid)).map((employee) => {
        const isSelected = selectedUsers.includes(employee.uid);
        
        return (
            <ListGroup.Item style={{ display: 'flex', justifyContent: 'space-between', gap: 6 }} key={employee.uid} action active={isSelected} onClick={() => {handleSelectEmployee(employee.uid)}}>
                <span>{`${employee.lastName}, ${employee.firstName} ${employee.middleName ?? ''} ${employee.pay.payType == -1 ? `(Terminated ${moment(employee.pay.dateEffective).format('MMM D, YYYY')})` : ''}`}</span>
                <span>{employee.location.name}</span>
            </ListGroup.Item>
        )
    });
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Select Employees to Include in Payroll</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                {usersNotInEntries.length > 0 &&
                    <CustomTabs value={listSwitch} setValue={setListSwitch} flex tabs={['Employee Filter', 'Add Other Employees']}/>
                }
                { listSwitch == 0 ?
                    <ListGroup>
                        {rows}
                    </ListGroup>
                    :
                    <ListGroup>
                        {usersNotInEntries}
                    </ListGroup>
                }
            </Modal.Body>
            { listSwitch == 1 && 
                <Modal.Footer>
                    <CustomButton onClick={handleSubmit} disabled={selectedUsers.length == 0} isLoading={isSubmitting} label='Add Selected Employees'/>
                </Modal.Footer>
            }
        </>
    )
}