import React, { useEffect, useState } from 'react'
import { getPermissionsTemplate } from '../../services/HumanResourcesService'
import SortableTable, { SortableTableCol, SortableTableRow } from '../../components/SortableTable';
import moment from 'moment';
import { Button, ButtonGroup, Dropdown, Form, Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import SearchBar from '../../components/SearchBar';
import PageSpinner from '../../components/PageSpinner';
import HumanResourcesHire from './HumanResourcesHire';
import CustomControl from '../../components/CustomControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleCheck, faClock, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { intToTime, timeOffStatusDictionary } from '../../tools';
import List from '../../components/List/List';
import HumanResourcesTimesheet from './HRTimesheet/HumanResourcesTimesheet';
import Cookies from 'universal-cookie';


export default function HumanResourcesHub({employees, setSelectedEmployee, selectedEmployee, dateSelected, setDateSelected, loadHub, locations}) {
    const permissions = new Cookies().get('userData')['pay']['permissions'];
    const [isLoading, setIsLoading] = useState(false);
    const [userSearch, setUserSearch] = useState('');
    const [showHireModal, setShowHireModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [permissionsTemplate, setPermissionsTemplate] = useState([]);
    const [showTerminated, setShowTerminated] = useState(false);

    const [selectedUserForModal, setSelectedUserForModal] = useState(undefined);

    async function loadData(){
        setIsLoading(true);
        const response = await getPermissionsTemplate();
        if (response.status === 200) {
            setPermissionsTemplate(response.permissionsTemplate);
        }
        setIsLoading(false);
    }

    useEffect(()=>{
        loadData();
    }, [])
  
    const allTitles = employees.reduce((prev, curr) => {
        return prev.includes(curr.pay.title) ? prev : prev.concat([curr.pay.title]);
    }, []);

    const [titleFilter, setTitleFilter] = useState(allTitles);
    const [locationFilter, setLocationFilter] = useState(locations.filter(l => l.isEnabled).map(l => l.uid));
    const [employeeTypeFilter, setEmployeeTypeFilter] = useState([0, 1]);

    const columns = [
        new SortableTableCol('name', 'Name', null, (a, b) => {return a > b ? 1 : a < b ? -1 : 0}, null),
        new SortableTableCol('title', 'Title', null, (a, b) => {return a > b ? 1 : a < b ? -1 : 0}, null),
        new SortableTableCol('location', 'Location', null, (a, b) => {return (a > b ? 1 : a < b ? -1 : 0)}, null),
        new SortableTableCol('schedule', 'Schedule', null),
        new SortableTableCol('clockInStatus', 'Clock-In Status ', 1, null, true)
    ]

    const rows = employees.filter((employee) => {
        const fullName = `${employee.lastName}, ${employee.firstName} ${employee.middleName ?? ''}`.toLowerCase();
        if(!fullName.includes(userSearch.toLowerCase())){
            return;
        }

        if(!showTerminated && employee.pay.payType === -1){
            return;
        }

        return titleFilter.includes(employee.pay.title) && locationFilter.includes(employee.locationIdentifier)  && employeeTypeFilter.includes(employee.pay.fulltime);
    }).map((employee) => {

        /////////////////
        /// Variables
        /////////////////
        let icon = faCircleCheck;
        let iconColor = '#17B169';
        let clockInStatus = 'Clocked Out';
        let scheduleText = 'Not Scheduled'

        let earliestShiftStart;
        let latestShiftEnd;

        let earliestInTime;
        let latestOutTime;

        employee.shifts?.forEach((shift)=>{
            if(!earliestShiftStart || shift.startTime < earliestShiftStart){
                earliestShiftStart = shift.startTime
            }
            if(shift.endTime && (!latestShiftEnd || latestShiftEnd > shift.endTime)) {
                latestShiftEnd = shift.endTime
            }
        })  

        employee.timesheetEntries?.forEach((entry)=>{
            if(!earliestInTime || entry.inPunch < earliestInTime.format('YYYY-MM-DD HH:mm:ss')) {
                earliestInTime = moment(entry.inPunch);
            }
            if(entry.outPunch && (!latestOutTime || latestOutTime.format('YYYY-MM-DD HH:mm:ss') < entry.outPunch)) {
                latestOutTime = moment(entry.outPunch)
            }
        })
        clockInStatus = !earliestInTime ? 'Not Clocked In' : `${`Clocked In At ${earliestInTime.format('h:mm A')}`}${latestOutTime ? ` - Clocked Out: ${latestOutTime.format('h:mm A')}` : ''}`

        /////////////////
        /// Coulmn Text
        /////////////////
        if (earliestInTime) { //clocked in on day
            if (employee.timeOff?.status === 1 
                || !employee.shifts?.length 
                || Math.abs(earliestInTime.diff(moment(employee.shifts[0].date).add(employee.shifts[0].startTime, 'minutes'), 'minutes')) > 30 
                || (latestOutTime ?? moment()).diff(earliestInTime, 'hours') > 12
            ) {
                icon = faTriangleExclamation;
                iconColor = 'gold';
            } else {
                icon = faCircleCheck;
                iconColor = '#17B169';
            }
        } else { // not clocked in
            if (employee.shifts.length && employee.timeOff?.status !== 1 && !employee.callOff) {
                if (dateSelected == moment().format('YYYY-MM-DD') && employee.shifts[0].startTime && moment().diff(moment().startOf('day'), 'minutes') < employee.shifts[0].startTime) {
                    icon = faCircleCheck;
                    iconColor = '#17B169';
                } else {
                    icon = faTriangleExclamation;
                    iconColor = 'red';
                }
            } else {
                icon = faCircleCheck;
                iconColor = '#17B169';
            }
        }

        
        scheduleText = 
            employee.timeOff?.status === 1 ? `Accepted Time Off Request` 
            : employee.callOff ? employee.callOff.notifiedManager ? 'Call Off' : 'No Show' 
            : employee.shifts.length ? `${employee.shifts[0].title}: ${intToTime(employee.shifts[0].startTime)} - ${intToTime(employee.shifts[0].endTime)}`
            : employee.timeOff ? `${employee.timeOff.status == 0 ? 'Pending' : 'Rejected'} Time Off Request`
            : 'Nothing Scheduled'
        ;

        /////////////////
        /// Popover Items
        /////////////////
        let items = [];
        employee.shifts.length && items.push(...employee.shifts?.map((s, i)=>{
            return [`${s.title}`, `Time: ${intToTime(s.startTime)} - ${intToTime(s.endTime)}`]
        }))

        employee.timeOff && items.push([`${timeOffStatusDictionary(employee.timeOff?.status)} Time Off Request`, `Date:  ${moment(employee.timeOff.startDate).format('MMM D, YYYY')} - ${moment(employee.timeOff.endDate).format('MMM D, YYYY')}`]);
        employee.callOff && items.push([`Status: ${employee.callOff.notifiedManager ? 'Call Off' : 'No Show'}`, `Submission Date:  ${moment(employee.callOff.submissionDate).format('MMM D, YYYY')}`]);
        
        const popover = (
            <Popover style={{position:'fixed', maxWidth: 1000}}>
                <Popover.Body>
                    <List items={items} responsive={true}/>
                </Popover.Body>
            </Popover>
        );

        return new SortableTableRow(
            {
                name: employee.lastName,
                title: employee.pay.title,
                location: employee.location.name,
            }, 
            (
                <tr key={employee.uid} className='cursor-pointer' style={{verticalAlign: 'middle'}} onClick={() => {setSelectedEmployee(employee)}}>
                    <td>{`${employee.lastName}, ${employee.firstName} ${employee.middleName ?? ''} ${employee.pay.payType === -1 ? '(TERMINATED)' : ''}`}</td>
                    <td>{employee.pay.title}</td>
                    <td>{employee.location.name}</td>
                    <td>
                        {scheduleText}
                        {items.length > 1 && 
                            <OverlayTrigger placement={'top'} overlay={popover}>
                                <Button style={{marginLeft: 18}} variant={selectedEmployee?.uid === employee.uid ? 'outline-light' : 'outline-primary'}>+{items.length - 1}</Button>
                            </OverlayTrigger>
                        }
                    </td>
                    <td style={{display: 'flex', whiteSpace: 'nowrap'}}>
                        <div onClick={(e) => {e.stopPropagation()}}>
                            <Button onClick={()=>{setShowModal(true); setSelectedUserForModal(employee)}} variant={selectedEmployee?.uid === employee.uid ? 'outline-light' : 'outline-primary'} style={{fontSize: 18}}>
                                {clockInStatus}
                                <FontAwesomeIcon style={{marginLeft: 8, color: iconColor}} icon={icon} size='1x'/>
                            </Button>
                        </div>
                    </td>
                </tr>
            )
        )
    })

    const titleFilterDropdownItems = allTitles.filter(t => t !== 'Terminated').map((title) => {
        return (
            <Dropdown.Item key={title} active={titleFilter.includes(title)} onClick={() => {
                if (titleFilter.includes(title)) {
                    setTitleFilter(titleFilter.filter(t => t !== title));
                } else {
                    setTitleFilter(titleFilter.concat([title]));
                }
            }}>
                {title}
            </Dropdown.Item>
        )
    })
    
    const locationFilterDropdownItems = locations.map((location) => {
        return (
            <Dropdown.Item key={location.uid} active={locationFilter.includes(location.uid)} onClick={() => {
                if (locationFilter.includes(location.uid)) {
                    setLocationFilter(locationFilter.filter(l => l !== location.uid));
                } else {
                    setLocationFilter(locationFilter.concat([location.uid]));
                }
            }}>
                {`${location.name} ${location.isEnabled ? '' : '(Disabled)'}`}
            </Dropdown.Item>
        )
    })

    const employeeTypeFilterDropdownItems = [1, 0].map((employeeType) => {
        return (
            <Dropdown.Item key={employeeType} active={employeeTypeFilter.includes(employeeType)} onClick={() => {
                if (employeeTypeFilter.includes(employeeType)) {
                    setEmployeeTypeFilter(employeeTypeFilter.filter(et => et !== employeeType));
                } else {
                    setEmployeeTypeFilter(employeeTypeFilter.concat([employeeType]));
                }
            }}>
                {employeeType ? 'Full-Time' : 'Part-Time'}
            </Dropdown.Item>
        )
    })
    
    return isLoading ? <PageSpinner/> : (
        <div style={{paddingTop: 12, display: 'flex', flexDirection: 'column', gap: 12, overflow: 'hidden'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
                <div>
                    <CustomControl floatingLabel title={'Date for Schedule & Clock-In Status'} value={dateSelected || moment().format('YYYY-MM-DD')} setValue={(value)=>setDateSelected(value ? value : moment().format('YYYY-MM-DD'))} style={{width: 290}} type='date'/>
                </div>
                {permissions.hrHire === 1 && <Button onClick={()=> setShowHireModal(true)} variant='outline-primary'>Hire New Employee</Button>}
            </div>
            <div style={{display: 'flex', gap: 8, flexWrap: 'wrap', alignItems: 'center'}}>
                <div style={{flex: 1}}>
                    <SearchBar label='Search by name' value={userSearch} setValue={(value) => setUserSearch(value)}/>
                </div>
                <Dropdown autoClose='outside'>
                    <Dropdown.Toggle variant='outline-primary'>Filter By...</Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Header>Title</Dropdown.Header>
                        {titleFilterDropdownItems}
                        <Dropdown.Divider/>
                        <Dropdown.Header>Location</Dropdown.Header>
                        {locationFilterDropdownItems}
                        <Dropdown.Divider/>
                        <Dropdown.Header>Employee Type</Dropdown.Header>
                        {employeeTypeFilterDropdownItems}
                    </Dropdown.Menu>
                </Dropdown>
                <Form.Check 
                    style={{whiteSpace: 'nowrap'}}
                    type="switch"
                    label="Show Terminated"
                    reverse
                    checked={showTerminated}
                    onChange={()=>setShowTerminated(!showTerminated)}
                />
            </div>
            <div style={{overflow: 'auto'}}>
                <SortableTable title={'Employees'} columns={columns} rows={rows} bordered striped hover size='sm'/>
            </div>
            <Modal show={showModal} onHide={()=>setShowModal(false)} size='xl' >
                <Modal.Header closeButton>
                    <Modal.Title>{`Timesheet: ${selectedUserForModal?.lastName}, ${selectedUserForModal?.firstName} ${selectedUserForModal?.middleName ?? ''}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HumanResourcesTimesheet crudCallback={loadHub}  defaultEndDate={moment(dateSelected).add(1, 'days').format('YYYY-MM-DD')} defaultStartDate={dateSelected} selectedEmployee={selectedUserForModal}/>
                </Modal.Body>
            </Modal>
            <Modal size='xl'  show={showHireModal} backdrop='static' onHide={()=> setShowHireModal(false)}>
                <HumanResourcesHire employees={employees} loadHub={loadHub} permissionsTemplate={permissionsTemplate} locations={locations}/>
            </Modal>
        </div>
    )
}
