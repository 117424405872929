/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import PageSpinner from '../../components/PageSpinner';
import { getAllMyDocuments, getMyDocument } from '../../services/MyDocumentsService';
import QuickTable from '../../components/QuickTable';
import moment from 'moment';
import { OnboardingDocumentsReadOnly, pandaDocStatusDictionary, saveFile } from '../../tools';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { AppGlobals } from '../../App';
import CustomAlert from '../../components/CustomAlert';


export default class MyDocuments extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            documents: [],
            pandaDocs: [],
            edDocumentRequests: [],
        }

        this.handleDownloadDocument = this.handleDownloadDocument.bind(this);
    }

    async loadData() {
        this.setState({isLoading: true});

        const response = await getAllMyDocuments();

        if (response.status === 200) {
            this.setState({documents: response.documents, pandaDocs: response.pandaDocs, edDocumentRequests: response.edDocumentRequests});
        }

        this.setState({isLoading: false});
    }

    componentDidMount() {
        this.loadData();
    }

    async handleDownloadDocument(uid) {
        const response = await getMyDocument(uid);
        if (response.status === 200) {
            saveFile(response.document.name, response.document.base64);
        } else {
            AppGlobals.alert(<CustomAlert type={'danger'} title={response.message ?? 'Failed to Download Document'}/>)
        }
    }


    render() {

        const edDocumentRequests = this.state.edDocumentRequests.map((documentRequest) => {
            let includedDocuments = Object.keys(documentRequest.documents)
                .filter((key) => OnboardingDocumentsReadOnly[key] !== undefined)
                .map((key) => OnboardingDocumentsReadOnly[key])
                // .join(', ');
            if(documentRequest.documents.custom.length > 0){
                includedDocuments.push(...documentRequest.documents.custom.map((document) => document.documentInfo.name))
            }
            includedDocuments = includedDocuments.join(', ')

            const status = documentRequest.isComplete ? 'Complete' : 'Action Required';

            return (
                <tr key={documentRequest.uid}>
                    <td style={{whiteSpace: 'nowrap'}}>{moment(documentRequest.dateCreated).format("MMM D, YYYY")}</td>
                    <td>{includedDocuments}</td>
                    <td>{status}</td>
                    <td>
                        <a 
                        href={`https://mytacticalrecruiting.com/employee-onboarding/${documentRequest.employeeToken}`}
                         target="_blank"
                            // rel="noopener noreferrer"
                        >View Request</a>
                    </td>
                </tr>
            )
        })

        const documentsToSignRows = this.state.pandaDocs.filter(d => d.status !== 'document.completed').map((doc) => {
            return (
                <tr key={doc.uid}>
                    <td>{doc.name}</td>
                    <td>{moment(doc.dateCreated).format('MMM D, YYYY')}</td>
                    <td>{pandaDocStatusDictionary[doc.status]}</td>
                    <td>
                        <span style={{opacity: 0.5}}>Check your email for a link to view & sign this document</span>
                    </td>
                </tr>
            )
        })

        const signedDocumentsRows = this.state.pandaDocs.filter(d => d.status === 'document.completed').map((doc) => {
            return (
                <tr key={doc.uid}>
                    <td>{doc.name}</td>
                    <td>{moment(doc.dateCreated).format('MMM D, YYYY')}</td>
                    <td>
                        <span style={{opacity: 0.5}}>Check your email for a link to view this document</span>
                    </td>
                </tr>
            )
        })

        const acknowledgedDocumentsRows = this.state.documents.map((doc) => {
            return (
                <tr key={doc.uid} style={{verticalAlign: 'middle'}}>
                    <td>{doc.name}</td>
                    <td>
                        <Button variant='outline-primary' onClick={() => {this.handleDownloadDocument(doc.uid)}}>
                            <FontAwesomeIcon icon={faDownload}/>
                        </Button>
                    </td>
                </tr>
            )
        })



        return this.state.isLoading ? <PageSpinner/> : (
            <div style={{padding: 16, overflowY: 'scroll', height: '100%'}}>
                {edDocumentRequests.length > 0 && <QuickTable title={'Documents to Sign'} headers={['Date Created', 'Documents Requested', 'Status', 'Link']} rows={edDocumentRequests}/>}
                {documentsToSignRows.length > 0 && <QuickTable title={'Documents to Sign'} headers={['Name', 'Date Created', 'Status', 'Link']} rows={documentsToSignRows}/>}
                {signedDocumentsRows.length > 0 && <QuickTable title={'Signed Documents'} headers={['Name', 'Date Created', 'Link']} rows={signedDocumentsRows}/>}
                {acknowledgedDocumentsRows.length > 0 && <QuickTable title={'Acknowledged Company Documents'} headers={['Name', 'Download']} widths={[null, 1]} rows={acknowledgedDocumentsRows}/>}
                {edDocumentRequests.length === 0 &&
                documentsToSignRows.length === 0 &&
                signedDocumentsRows.length === 0 &&
                acknowledgedDocumentsRows.length === 0 && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            fontSize: '1.5rem',
                            color: '#888',
                        }}
                    >
                        No Documents
                    </div>
                )}
            </div>
        )
    }
}